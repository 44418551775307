export const loginUser = user => {
    return {
      type: 'LOGIN_USER',
      user: user
    }
}

export const logoutUser = () => {
    return {
      type: 'LOGOUT_USER'
    }
}

export const importHistory = (id) => {
  return {
    type: 'IMPORT_HISTORY',
    id: id
  }
}