
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { db } from "../../fire.js";
import firebase from "../../fire.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { Button, Select, Avatar, Input, Upload, Icon, Modal, message, Popconfirm, Switch } from "antd";
import { typography } from "material-ui/styles";
import * as Colors from "material-ui/styles/colors";
const { TextArea } = Input;
const Option = Select.Option;

const algoliasearch = require('algoliasearch');

const ALGOLIA_ID = "3XH40BADRA";
const ALGOLIA_ADMIN_KEY = "d5013f3793e173fc34ca0a0a075a365f"
const ALGOLIA_SEARCH_KEY = "f8c49228bd0d2ba764dbebb55f2d0569";

const ALGOLIA_INDEX_NAME = 'profiles';
const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#002F49",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});


const styles = {
  navigation: {
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: "grey",
    paddingBottom: 15,
    display: "block"
  },
  title: {
    fontSize: "1.2em",
    paddingTop: ".50em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitle: {
    fontSize: "1em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitleTime: {
    fontSize: "0.7em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  subTitle: {
    paddingTop: "0.2em",
    paddingRight: "1.5em",
    paddingBottom: "1.0em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightRegular,
    color: "#666",
    fontFamily: "Avenir Next"
  },
  cardLeft: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "1%",
    width: "24.5%",
    float: "left",
    marginBottom: "1.3em",
    fontFamily: "Avenir Next"
  },
  cardRight: {
    padding: 30,
    marginTop: "1.3em",
    width: "24.5%",
    float: "left",
    fontFamily: "Avenir Next"
  },
  cardBoth: {
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    fontFamily: "Avenir Next"
  },
  proPic: {
    marginTop: 15,
    height: "100%",
    width: "100%"
  },
  rightBlock: {
    float: "left",
    marginLeft: 15,
    paddingBottom: 15
  },
  paperTablet: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    fontFamily: "Avenir Next",
    overflow: "auto",
    borderRadius: "4px"
  },
  paperMobile: {
    marginTop: "2.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "0px"
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  profileInfoCard: {
    padding: 1,
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both",
    borderRadius: "4px"
  },
  paper: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "10px"
  },
  paperGallery: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: 0
  },
  stickDiv: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    position: "sticky",
    top: "20px"
  },
  stickDivSafari: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    top: "20px"
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  accountHeaderItem: {
    paddingTop: "0.3em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightLight,
    color: "#666",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: typography.fontWeightMedium,
    marginTop: 5,
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  caseHeader: {
    fontSize: 15,
    paddingTop: 25,
    display: "flex"
  },
  lineItem: {
    fontSize: 15,
    paddingTop: 25
  },
  lineItemFirst: {
    fontSize: 15,
    paddingTop: 15
  },
  lineTitle: {
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Avenir Next",
    width: "100%",
    color: "#353535",
    paddingBottom: "4px",
    color: "#55360"
  },
  lineValue: {
    fontWeight: 400,
    width: "100%",
    fontSize: "15px",
    whiteSpace: "pre-line",
    color: "#353535"
  },
  verifiedInfo: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  verifiedInfoGray: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
    display: "inline-block",
    verticalAlign: "middle"
  },
  actionCard: {
    padding: 15,
    marginTop: "1.3em",
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both"
  },
  actionCardMobile: {
    padding: 15,
    marginTop: "1.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  proPicSmall: {
    float: "right",
    height: 35,
    marginRight: 10,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  caseIcon: {
    float: "left",
    height: 35,
    marginRight: 10,
    marginTop: 5,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  proPicSmallMobile: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "4px"
  },
  refProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  pocProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  nameLabel: {
    fontSize: "1.2em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    color: "#000000"
  },
  nameLabelMobile: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    widht: "100%",
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  refNameLabel: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  tag: {
    color: "#2f54eb",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
    borderRadius: "4px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: "0.9em",
    display: "inline-block"
  },
  cardHeader: {
    fontSize: 25,
    fontWeight: 600,
    paddingTop: 30,
    paddingLeft: 30,
    backgroundColor: "#ffffff",
    color: "black"
  },
  infoRow: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 30
  }
};
export class ProcessInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      changed: {}
    }; // <- set up react state
  }

  handleChange = (event, type) => {
    var data = this.state.process_info;

    var changed = this.state.changed;

    if (type == "working_hours") {
      data.working_hours = event.target.value;
      changed["working_hours"] = event.target.value;
    }


    this.setState({ process_info: data, changed: changed });
  };

  editCard(event) {
    this.setState({ editing: true });
  }

  cancelEdit(event) {
    var data = this.props.process_info;

    var copiedObject = Object.assign({}, data);

    this.setState({ process_info: copiedObject, editing: false, changed: {} });
  }

  saveCard(event) {
    var data = this.state.process_info;
    var changed = this.state.changed;

    console.log("id is =>", this.props.id);
    console.log("changed is =>", changed);

    var process_info = { process_info: changed };

    db.collection("profiles")
      .doc(this.props.id)
      .set(process_info, { merge: true })
      .then(
        function () {
          console.log("Document successfully written!");
          const index = client.initIndex(ALGOLIA_INDEX_NAME);
          index.partialUpdateObject({
            process_info: data,
            objectID: this.props.id
          }, function (err, content) {
            if (err) {
              console.log("oh shit an error")
            } else {
              console.log("pushed to Aloglia!")

              console.log(content);
            }
          });

          this.setState({ editing: false, changed: {} });
        }.bind(this)
      )
      .catch(
        function (error) {
          console.error("Error writing document: ", error);
        }.bind(this)
      );
  }

  handleChipChange = (value, type) => {
    var data = this.state.process_info;
    var changed = this.state.changed;

    console.log(`selected ${value}`);

    if (type == "add_services") {
      data.add_services = value;
      changed["add_services"] = value;
    }

    if (type == "dev_locations") {
      data.dev_locations = value;
      changed["dev_locations"] = value;
    }

    if (type == "payment_method") {
      data.payment_method = value;
      changed["payment_method"] = value;
    }

    if (type == "payment_structure") {
      data.payment_structure = value;
      changed["payment_structure"] = value;
    }

    this.setState({ process_info: data, changed: changed });
  };

  componentDidMount() {
    var data = this.props.process_info;

    console.log("process info is =>", data)

    /*          var tech = data
                        .speci_tech
                        .join(', '); */

    var copiedObject = Object.assign({}, data);

    this.setState({ process_info: copiedObject });
  }

  switchChecked(checked) {
    console.log(`switch to ${checked}`);

    var data = this.state.process_info;
    var changed = this.state.changed;

    data.dedicated_available = checked.toString();
    changed["dedicated_available"] = checked.toString();

    this.setState({ process_info: data, changed: changed });

  }

  render() {
    var store = this.props.store;
    var data = this.state.process_info;
    var process_info_rows = [];

    var accountType = 0;

    if (store.getState().userData != null) {
      accountType = store.getState().userData.acc_type;
    }

    if (data == null) {
      return null;
    }

    var dev_locations = [];

    if (data.dev_locations != null) {
      data.dev_locations.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        dev_locations.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }

    var payment_method = [];

    if (data.payment_method != null) {
      data.payment_method.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        payment_method.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }

    var payment_structure = [];

    if (data.payment_structure != null) {
      data.payment_structure.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        payment_structure.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }

    var add_services = [];

    if (data.add_services != null) {
      data.add_services.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        add_services.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }

    var dedicated_available_bool = (data.dedicated_available === "true");
    var dedicated_string = '';
    if (dedicated_available_bool) {
      dedicated_string = 'Yes';
    }
    else {
      dedicated_string = 'No';
    }



    if (data == null) {
      return null;
    } else {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <div style={styles.cardHeader}>Other Details</div>

            <div style={styles.infoRow}>

              {!this.state.editing && (

                <div>

                  {(data.add_services != null && data.add_services.length != 0) && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Additional Services</div>
                      <div style={styles.lineValue}>{add_services}</div>
                    </div>
                  )}


                  {(data.dev_locations != null) && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Developer Location(s)</div>
                      <div style={styles.lineValue}>{dev_locations}</div>
                    </div>
                  )}

                  {(data.dedicated_available != null) && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Dedicated Resources Available</div>
                      <div style={styles.lineValue}>{dedicated_string}</div>
                    </div>
                  )}

                  {(data.payment_method != null && data.payment_method.length != 0) && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Payment Methods</div>
                      <div style={styles.lineValue}>{payment_method}</div>
                    </div>
                  )}

                  {(data.payment_structure != null && data.payment_structure.length != 0) && (

                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Payment Structure</div>
                      <div style={styles.lineValue}>{payment_structure}</div>
                    </div>
                  )}

                  {(data.working_hours != null) && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Working Hours</div>
                      <div style={styles.lineValue}>{data.working_hours}</div>
                    </div>
                  )}

                </div>

              )}


              {this.state.editing && (
                <div>
                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Additional Services</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.add_services}
                      onChange={value => this.handleChipChange(value, "add_services")}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Developer Location(s)</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.dev_locations}
                      onChange={value => this.handleChipChange(value, "dev_locations")}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Dedicated Resources Available?</div>
                    <Switch checked={dedicated_available_bool} onChange={this.switchChecked.bind(this)} />
                  </div>


                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Payment Methods</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.payment_method}
                      onChange={value => this.handleChipChange(value, "payment_method")}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Payment structure</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.payment_structure}
                      onChange={value => this.handleChipChange(value, "payment_structure")}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Working Hours</div>
                    <Input
                      onChange={e => this.handleChange(e, "working_hours")}
                      value={data.working_hours}
                      placeholder="10am - 6pm (Central)"
                    />
                  </div>




                </div>
              )}



            </div>

            {accountType == 2 && (
              <div>
                {!this.state.editing && (
                  <Button
                    style={{
                      float: "right",
                      marginRight: 20,
                      marginBottom: 20
                    }}
                    onClick={event => this.editCard(event)}
                  >
                    Edit
                    </Button>
                )}

                {this.state.editing && (
                  <div style={{ float: "right" }}>
                    <Button
                      style={{ marginRight: 5, marginBottom: 20 }}
                      onClick={event => this.cancelEdit(event)}
                    >
                      Cancel
                      </Button>
                    <Button
                      type="primary"
                      style={{ marginRight: 20, marginBottom: 20 }}
                      onClick={event => this.saveCard(event)}
                    >
                      Save
                      </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div id="work" />
        </MuiThemeProvider>
      );
    }
  }
}