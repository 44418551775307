import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { db } from "../../fire.js";
import firebase from "../../fire.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { Button, Select, Avatar, Input, Upload, Icon, Modal, message, Popconfirm, Switch } from "antd";
import { typography } from "material-ui/styles";
import * as Colors from "material-ui/styles/colors";
const { TextArea } = Input;
const Option = Select.Option;

const algoliasearch = require('algoliasearch');

const ALGOLIA_ID = "3XH40BADRA";
const ALGOLIA_ADMIN_KEY = "d5013f3793e173fc34ca0a0a075a365f"
const ALGOLIA_SEARCH_KEY = "f8c49228bd0d2ba764dbebb55f2d0569";

const ALGOLIA_INDEX_NAME = 'profiles';
const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#002F49",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});

function isEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}

const styles = {
  navigation: {
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: "grey",
    paddingBottom: 15,
    display: "block"
  },
  title: {
    fontSize: "1.2em",
    paddingTop: ".50em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitle: {
    fontSize: "1em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitleTime: {
    fontSize: "0.7em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  subTitle: {
    paddingTop: "0.2em",
    paddingRight: "1.5em",
    paddingBottom: "1.0em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightRegular,
    color: "#666",
    fontFamily: "Avenir Next"
  },
  cardLeft: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "1%",
    width: "24.5%",
    float: "left",
    marginBottom: "1.3em",
    fontFamily: "Avenir Next"
  },
  cardRight: {
    padding: 30,
    marginTop: "1.3em",
    width: "24.5%",
    float: "left",
    fontFamily: "Avenir Next"
  },
  cardBoth: {
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    fontFamily: "Avenir Next"
  },
  proPic: {
    marginTop: 15,
    height: "100%",
    width: "100%"
  },
  rightBlock: {
    float: "left",
    marginLeft: 15,
    paddingBottom: 15
  },
  paperTablet: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    fontFamily: "Avenir Next",
    overflow: "auto",
    borderRadius: "4px"
  },
  paperMobile: {
    marginTop: "2.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "0px"
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  profileInfoCard: {
    padding: 1,
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both",
    borderRadius: "4px"
  },
  paper: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "10px"
  },
  paperGallery: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: 0
  },
  stickDiv: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    position: "sticky",
    top: "20px"
  },
  stickDivSafari: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    top: "20px"
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  accountHeaderItem: {
    paddingTop: "0.3em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightLight,
    color: "#666",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: typography.fontWeightMedium,
    marginTop: 5,
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  caseHeader: {
    fontSize: 15,
    paddingTop: 25,
    display: "flex"
  },
  lineItem: {
    fontSize: 15,
    paddingTop: 25
  },
  lineItemFirst: {
    fontSize: 15,
    paddingTop: 15
  },
  lineTitle: {
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Avenir Next",
    width: "100%",
    color: "#353535",
    paddingBottom: "4px",
    color: "#55360"
  },
  lineValue: {
    fontWeight: 400,
    width: "100%",
    fontSize: "15px",
    whiteSpace: "pre-line",
    color: "#353535"
  },
  verifiedInfo: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  verifiedInfoGray: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
    display: "inline-block",
    verticalAlign: "middle"
  },
  actionCard: {
    padding: 15,
    marginTop: "1.3em",
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both"
  },
  actionCardMobile: {
    padding: 15,
    marginTop: "1.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  proPicSmall: {
    float: "right",
    height: 35,
    marginRight: 10,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  caseIcon: {
    float: "left",
    height: 35,
    marginRight: 10,
    marginTop: 5,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  proPicSmallMobile: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "4px"
  },
  refProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  pocProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  nameLabel: {
    fontSize: "1.2em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    color: "#000000"
  },
  nameLabelMobile: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    widht: "100%",
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  refNameLabel: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  tag: {
    color: "#2f54eb",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
    borderRadius: "4px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: "0.9em",
    display: "inline-block"
  },
  cardHeader: {
    fontSize: 25,
    fontWeight: 600,
    paddingTop: 30,
    paddingLeft: 30,
    backgroundColor: "#ffffff",
    color: "black"
  },
  cardHeaderSecondary: {
    fontSize: 25,
    fontWeight: 600,
    paddingTop: 30,
    backgroundColor: "#ffffff",
    color: "black"
  },
  infoRow: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 30
  }
};

const dummyRequest = ({ file, onSuccess }) => {

  // Create a root reference
  var storageRef = firebase.storage().ref();

  // Create a reference to 'mountains.jpg'
  var mountainsRef = storageRef.child("mountains.jpg");

  var randomId =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15);

  var uploadTask = storageRef
    .child("images" + "/" + randomId + ".jpg")
    .put(file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    function (snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
      }
    },
    function (error) {
      // Handle unsuccessful uploads
    },
    function () {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then(
        function (downloadURL) {
          console.log("File available at", downloadURL);
          onSuccess(downloadURL);
        }.bind(this)
      );
    }.bind(this)
  );

};

export class CompanyInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      changed: {},
      changed_health: {},
      changed_contact: {}
    }; // <- set up react state
  }

  handleChange = (event, type) => {
    var data = this.state.company_info;
    var changed = this.state.changed;

    if (type == "short_bio") {
      data.short_bio = event.target.value;
      changed["short_bio"] = event.target.value;
    }

    if (type == "name") {
      data.name = event.target.value;
      changed["name"] = event.target.value;
    }

    if (type == "company_size") {
      data.company_size = event.target.value;
      changed["company_size"] = event.target.value;
    }

    if (type == "hq") {
      data.hq = event.target.value;
      changed["hq"] = event.target.value;
    }

    this.setState({ company_info: data, changed: changed });
  };

  editCard(event) {
    this.setState({ editing: true });
  }

  cancelEdit(event) {
    var data = this.props.company_info
    var data_health = this.props.health
    var data_contact = this.props.contact

    var copiedObject = Object.assign({}, data);
    var copiedObjectHealth = Object.assign({}, data_health);
    var copiedObjectContact = Object.assign({}, data_contact);

    this.setState({ company_info: copiedObject, health: copiedObjectHealth, contact: copiedObjectContact, editing: false, changed: {}, changed_contact: {}, changed_health: {} });
  }

  saveCard(event) {
    var data = this.state.company_info;
    var data_health = this.state.health
    var data_contact = this.state.contact
    var changed = this.state.changed;
    var changed_health = this.state.changed_health
    var changed_contact = this.state.changed_contact

    console.log("id is =>", this.props.id);
    console.log("changed is =>", changed);
    console.log("changed health is =>", changed_health);
    console.log("changed contact is =>", changed_contact);

    if (!isEmpty(changed) && changed != null) {
      var company_info = { company_info: changed };

      db.collection("profiles")
        .doc(this.props.id)
        .set(company_info, { merge: true })
        .then(
          function () {
            console.log("Document successfully written!");
            const index = client.initIndex(ALGOLIA_INDEX_NAME);
            index.partialUpdateObject({
              company_info: data,
              objectID: this.props.id
            }, function (err, content) {
              if (err) {
                console.log("oh shit an error")
              } else {
                console.log("pushed to Aloglia!")

                console.log(content);
              }
            });

            this.setState({ editing: false, changed: {} });
          }.bind(this)
        )
        .catch(
          function (error) {
            console.error("Error writing document: ", error);
          }.bind(this)
        );
    }

    if (!isEmpty(changed_health) && changed_health != null) {
      var health = { health: changed_health };

      db.collection("profiles")
        .doc(this.props.id)
        .set(health, { merge: true })
        .then(
          function () {
            console.log("Document successfully written!");

            const index = client.initIndex(ALGOLIA_INDEX_NAME);
            index.partialUpdateObject({
              health: data_health,
              objectID: this.props.id
            }, function (err, content) {
              if (err) {
                console.log("oh shit an error")
              } else {
                console.log("pushed to Aloglia!")

                console.log(content);
              }
            });

            this.setState({ editing: false, changed_health: {} });
          }.bind(this)
        )
        .catch(
          function (error) {
            console.error("Error writing document: ", error);
          }.bind(this)
        );
    }

    if (!isEmpty(changed_contact) && changed_contact != null) {
      var contact = { contact: changed_contact };

      db.collection("profiles")
        .doc(this.props.id)
        .set(contact, { merge: true })
        .then(
          function () {
            console.log("Document successfully written!");
            const index = client.initIndex(ALGOLIA_INDEX_NAME);
            index.partialUpdateObject({
              contact: data_contact,
              objectID: this.props.id
            }, function (err, content) {
              if (err) {
                console.log("oh shit an error")
              } else {
                console.log("pushed to Aloglia!")

                console.log(content);
              }
            });
            this.setState({ editing: false, changed_contact: {} });
          }.bind(this)
        )
        .catch(
          function (error) {
            console.error("Error writing document: ", error);
          }.bind(this)
        );
    }



  }

  handleChipChange = (value, type) => {
    var data = this.state.company_info;
    var changed = this.state.changed;

    console.log(`selected ${value}`);

    if (type == "offerings") {
      data.offerings = value;
      changed["offerings"] = value;
    }

    if (type == "speci_tech") {
      data.speci_tech = value;
      changed["speci_tech"] = value;
    }

    if (type == "speci_industry") {
      data.speci_industry = value;
      changed["speci_industry"] = value;
    }

    this.setState({ company_info: data, changed: changed });
  };

  onChange = info => {

    var data = this.state.company_info;
    var changed = this.state.changed;

    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        console.log("info is =>", info.file.response)
        data["image"] = info.file.response;
        changed["image"] = info.file.response;
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState({ company_info: data, changed: changed, selectedFile: nextState.selectedFile, selectedFileList: nextState.selectedFileList });
  };

  onChangeAvatarPOC = info => {

    var data = this.state.contact;
    var changed_contact = this.state.changed_contact;

    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        console.log("info is =>", info.file.response)
        data["image"] = info.file.response;
        changed_contact["image"] = info.file.response;
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState({ contact: data, changed_contact: changed_contact, selectedFilePOC: nextState.selectedFile, selectedFileListPOC: nextState.selectedFileList });
  };

  switchChecked(checked) {
    console.log(`switch to ${checked}`);

    var data = this.state.health;
    var changed_health = this.state.changed_health;

    data.di_tested = checked;
    changed_health["di_tested"] = checked;

    this.setState({ health: data, changed_health: changed_health });
  }

  handleChangeHealth = (event, type) => {
    var data = this.state.health;

    var changed_health = this.state.changed_health;

    if (type == "ver_case_count") {
      data.ver_case_count = event.target.value;
      changed_health["ver_case_count"] = event.target.value;
    }

    if (type == "ver_ref_count") {
      data.ver_ref_count = event.target.value;
      changed_health["ver_ref_count"] = event.target.value;
    }

    this.setState({ health: data, changed_health: changed_health });
  };

  handleChangeContact = (event, type) => {
    var data = this.state.contact;

    var changed_contact = this.state.changed_contact;

    if (type == "name") {
      data.name = event.target.value;
      changed_contact["name"] = event.target.value;
    }

    if (type == "skype") {
      data.skype = event.target.value;
      changed_contact["skype"] = event.target.value;
    }

    if (type == "timezone") {
      data.timezone = event.target.value;
      changed_contact["timezone"] = event.target.value;
    }

    if (type == "title") {
      data.title = event.target.value;
      changed_contact["title"] = event.target.value;
    }

    this.setState({ contact: data, changed_contact: changed_contact });
  };


  componentDidMount() {
    var data = this.props.company_info
    var data_health = this.props.health
    var data_contact = this.props.contact

    var copiedObject = Object.assign({}, data);
    var copiedObjectHealth = Object.assign({}, data_health);
    var copiedObjectContact = Object.assign({}, data_contact);

    this.setState({ company_info: copiedObject, health: copiedObjectHealth, contact: copiedObjectContact });
  }

  render() {
    var store = this.props.store;
    var data = this.state.company_info;
    var data_health = this.state.health
    var data_contact = this.state.contact
    var company_info_rows = [];

    var accountType = 0;

    if (store.getState().userData != null) {
      accountType = store.getState().userData.acc_type;
    }

    if (data == null) {
      return null;
    }

    var tech = [];
    var offerings = [];
    var offering_tags = [];
    var ind = [];

    if (data.offerings != null) {
      data.offerings.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        offerings.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
        offering_tags.push(<Option key={t}>{t}</Option>);
      });
    }

    if (data.speci_tech != null) {
      data.speci_tech.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        tech.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }

    if (data.speci_industry) {
      data.speci_industry.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        ind.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }



    var isFlat = false;
    var lowest_rate = 1000;
    var highest_rate = 0;
    var flat_rate = 0;

    if (data.rates != null) {
      Object.keys(data.rates).forEach(function (key) {
        console.log(key, data.rates[key]);
        if (data.rates[key] < lowest_rate) {
          lowest_rate = data.rates[key];
        }

        if (data.rates[key] > highest_rate) {
          highest_rate = data.rates[key];
        }

        if (key == "flat") {
          isFlat = true;
          flat_rate = data.rates[key];
        }
      });
    }




    if (data.name == null) {
      return null;
    } else {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <div style={styles.cardHeader}>The Company</div>

            <div style={styles.infoRow}>

              {!this.state.editing && (

                <div>

                  {(data.short_bio != null && data.short_bio != "") && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Summary</div>
                      <div style={styles.lineValue}>{data.short_bio}</div>
                    </div>
                  )}


                  {(data.company_size != null && data.company_size != "") && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Company Size</div>
                      <div style={styles.lineValue}>{data.company_size}</div>
                    </div>
                  )}

                  {(data.offerings != null && data.offerings != "") && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>
                        They mainly focus on these platforms
            </div>
                      <div style={styles.lineValue}>{offerings}</div>
                    </div>
                  )}

                  {(data.speci_tech != null && data.speci_tech != "") && (
                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>
                        For the tech savvy, languages they work with
            </div>
                      <div style={styles.lineValue}>{tech}</div>
                    </div>
                  )}

                  {(data.speci_industry != null && data.speci_industry != "" && accountType == 2) && (

                    <div style={styles.lineItem}>
                      <div style={styles.lineTitle}>Industries they've worked with</div>
                      <div style={styles.lineValue}>{ind}</div>
                    </div>
                  )}



                </div>

              )}


              {this.state.editing && (
                <div>


                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Company Name</div>
                    <Input
                      onChange={e => this.handleChange(e, "name")}
                      value={data.name}
                      placeholder="Aloa"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Headquarters</div>
                    <Input
                      onChange={e => this.handleChange(e, "hq")}
                      value={data.hq}
                      placeholder="Lucknow, India"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Company Image</div>
                    <Upload
                      fileList={this.state.selectedFileList}
                      customRequest={dummyRequest}
                      onChange={this.onChange}
                    >
                      <Button>
                        <Icon type="upload" /> Click to Upload
                    </Button>
                    </Upload>
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Aloa Partner Network Certified</div>
                    <Switch checked={data_health.di_tested} onChange={this.switchChecked.bind(this)} />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Verified Case Count</div>
                    <Input
                      onChange={e => this.handleChangeHealth(e, "ver_case_count")}
                      value={data_health.ver_case_count}
                      placeholder="2"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Verified References Count</div>
                    <Input
                      onChange={e => this.handleChangeHealth(e, "ver_ref_count")}
                      value={data_health.ver_ref_count}
                      placeholder="2"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Summary</div>
                    <TextArea
                      onChange={e => this.handleChange(e, "short_bio")}
                      value={data.short_bio}
                      placeholder="Just a short bio"
                      autosize={{ minRows: 2, maxRows: 6 }}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Company Size</div>
                    <Input
                      onChange={e => this.handleChange(e, "company_size")}
                      value={data.company_size}
                      placeholder="10-15 full-time employees"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>They mainly focus on these platforms</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.offerings}
                      onChange={value => this.handleChipChange(value, "offerings")}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>For the tech savvy, languages they work with</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.speci_tech}
                      onChange={value => this.handleChipChange(value, "speci_tech")}
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Industries they've worked with</div>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={data.speci_industry}
                      onChange={value => this.handleChipChange(value, "speci_industry")}
                    />
                  </div>



                  <div style={styles.cardHeaderSecondary}>Point of Contact</div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Contact Image</div>
                    <Upload
                      fileList={this.state.selectedFileListPOC}
                      customRequest={dummyRequest}
                      onChange={this.onChangeAvatarPOC}
                    >
                      <Button>
                        <Icon type="upload" /> Click to Upload
                    </Button>
                    </Upload>
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Name</div>
                    <Input
                      onChange={e => this.handleChangeContact(e, "name")}
                      value={data_contact.name}
                      placeholder="Pavel"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Title</div>
                    <Input
                      onChange={e => this.handleChangeContact(e, "title")}
                      value={data_contact.title}
                      placeholder="Account Manager"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Skype Username</div>
                    <Input
                      onChange={e => this.handleChangeContact(e, "skype")}
                      value={data_contact.skype}
                      placeholder="skype_username"
                    />
                  </div>

                  <div style={styles.lineItem}>
                    <div style={styles.lineTitle}>Timezone</div>
                    <Input
                      onChange={e => this.handleChangeContact(e, "timezone")}
                      value={data_contact.timezone}
                      placeholder="Europe/Moscow"
                    />
                  </div>


                </div>
              )}



            </div>

            {accountType == 2 && (
              <div>
                {!this.state.editing && (
                  <Button
                    style={{
                      float: "right",
                      marginRight: 20,
                      marginBottom: 20
                    }}
                    onClick={event => this.editCard(event)}
                  >
                    Edit
                    </Button>
                )}

                {this.state.editing && (
                  <div style={{ float: "right" }}>
                    <Button
                      style={{ marginRight: 5, marginBottom: 20 }}
                      onClick={event => this.cancelEdit(event)}
                    >
                      Cancel
                      </Button>
                    <Button
                      type="primary"
                      style={{ marginRight: 20, marginBottom: 20 }}
                      onClick={event => this.saveCard(event)}
                    >
                      Save
                      </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div id="work" />
        </MuiThemeProvider>
      );
    }
  }
}