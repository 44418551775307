import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { TopBar } from "./App";
import { Account } from "./components/Account";
import { Profile } from "./components/DevProfile";
import { Search } from "./components/Search";
import { NotFound } from "./components/404";
import { Login } from "./components/Auth";
import registerServiceWorker from "./registerServiceWorker";
import { auth, db } from "./fire.js";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import filtering from "./reducers/search";
import { loginUser } from "./actions/auth";
import thunkMiddleware from "redux-thunk";
import qs from "query-string";
import Auth from "./Auth";
import Loading from "./components/Loading";

var store = createStore(filtering, applyMiddleware(thunkMiddleware));

var user = auth.currentUser;

const auth0 = new Auth();
auth0
  .checkSession()
  .then(result => {
    const { idToken } = result;
    if (idToken) {
      auth0.handleFirebaseToken(idToken);
    }
  })
  .catch(error => {
    console.log(error);
    if (error.error === "login_required") {
      auth.signOut();
      store.dispatch(loginUser(null));
    }
  });

const basename = process.env.REACT_APP_BASE_ROUTE
  ? process.env.REACT_APP_BASE_ROUTE
  : "/";

const App = () => (
  <div>
    <TopBar store={store} />

    <Switch>
      <Route
        exact
        path="/search"
        render={props => (
          <Search
            user={user}
            store={store}
            query={qs.parse(props.location.search)}
          />
        )}
      />
      <Route
        exact
        path="/"
        render={props => (
          <Search
            user={user}
            store={store}
            query={qs.parse(props.location.search)}
          />
        )}
      />
      <Route
        exact
        path="/profile/:devId"
        render={props => (
          <Profile devId={props.match.params.devId} store={store} />
        )}
      />

      <Route path="/account" render={props => <Account store={store} />} />
      <Route path="/login" render={props => <Login store={store} />} />
      <Route path="/callback" render={() => <Redirect to="/" />} />
      <Route path="*" render={props => <NotFound store={store} />} />
    </Switch>
  </div>
);

const AppNotLoggedIn = () => (
  <div>
    <TopBar store={store} />

    <Switch>
      <Route path="*" render={props => <div style={{padding: "30px"}}>Reach out to partners@aloa.co for access</div>} />
      <Route
	exact
        path="/callback"
        render={props => {
          auth0
            .handleAuthentication(props)
            .then(() => {
              props.history.push("/");
            })
            .catch(error => {
              console.error(error.message);
              props.history.push("/");
            });
          return <Loading />;
        }}
      />
     
    </Switch>
  </div>
);

ReactDOM.render(<Loading />, document.getElementById("root"));
auth.onAuthStateChanged(function(tempuser) {
  if (tempuser) {
    // User is signed in.

    user = tempuser;

    var profilesRef = db.collection("users").doc(user.uid);

    profilesRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          store.dispatch(loginUser(doc.data()));

          ReactDOM.render(
            <BrowserRouter basename={basename}>
              <App />
            </BrowserRouter>,
            document.getElementById("root")
          );

          const script = document.createElement("script");

          script.src = "https://swc.cdn.skype.com/sdk/v1/sdk.min.js";
          script.async = true;

          document.head.appendChild(script);

          const script2 = document.createElement("script");

          const scriptText2 = document.createTextNode(
            "!function() { var t; if (t = window.driftt = window.drift = window.driftt || []," +
              " !t.init) return t.invoked ? void (window.console && console.error && console.er" +
              "ror('Drift snippet included twice.')) : (t.invoked = !0,  t.methods = [ 'identif" +
              "y', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', " +
              "'on' ],  t.factory = function(e) { return function() { var n; return n = Array.p" +
              "rototype.slice.call(arguments), n.unshift(e), t.push(n), t; }; }, t.methods.forE" +
              "ach(function(e) { t[e] = t.factory(e); }), t.load = function(t) { var e, n, o, i" +
              "; e = 3e5, i = Math.ceil(new Date() / e) * e, o = document.createElement('script" +
              "'),  o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.sr" +
              "c = 'https://js.driftt.com/include/' + i + '/' + t + '.js',  n = document.getEle" +
              "mentsByTagName('script')[0], n.parentNode.insertBefore(o, n); }); }(); drift.SNI" +
              "PPET_VERSION = '0.3.1'; drift.load('7dutxpneim9g');"
          );

          script2.appendChild(scriptText2);
          document.head.appendChild(script2);

          /*
          const script2 = document.createElement("script");

          const scriptText2 = document.createTextNode("window.$crisp=[];window.CRISP_WEBSITE_ID='49a54f41-44e2-4564-81b9-a747052a711e';(function(){d=document;s=d.createElement('script');s.src='https://client.crisp.chat/l.js';s.async=1;d.getElementsByTagName('head')[0].appendChild(s);})();");

          script2.appendChild(scriptText2);
          document
            .head
            .appendChild(script2);  */
        } else {
          console.error(`${user.uid} does not exist in database`);
          ReactDOM.render(
            <BrowserRouter basename={basename}>
              <AppNotLoggedIn />
            </BrowserRouter>,
            document.getElementById("root")
          );

          const script2 = document.createElement("script");

          const scriptText2 = document.createTextNode(
            "!function() { var t; if (t = window.driftt = window.drift = window.driftt || []," +
              " !t.init) return t.invoked ? void (window.console && console.error && console.er" +
              "ror('Drift snippet included twice.')) : (t.invoked = !0,  t.methods = [ 'identif" +
              "y', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', " +
              "'on' ],  t.factory = function(e) { return function() { var n; return n = Array.p" +
              "rototype.slice.call(arguments), n.unshift(e), t.push(n), t; }; }, t.methods.forE" +
              "ach(function(e) { t[e] = t.factory(e); }), t.load = function(t) { var e, n, o, i" +
              "; e = 3e5, i = Math.ceil(new Date() / e) * e, o = document.createElement('script" +
              "'),  o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.sr" +
              "c = 'https://js.driftt.com/include/' + i + '/' + t + '.js',  n = document.getEle" +
              "mentsByTagName('script')[0], n.parentNode.insertBefore(o, n); }); }(); drift.SNI" +
              "PPET_VERSION = '0.3.1'; drift.load('7dutxpneim9g');"
          );

          script2.appendChild(scriptText2);
          document.head.appendChild(script2);
        }
      })
      .catch(function(error) {});

    // ...
  } else {
    // User is signed out. ...

    ReactDOM.render(
      <BrowserRouter basename={basename}>
        <AppNotLoggedIn />
      </BrowserRouter>,
      document.getElementById("root")
    );

    const script2 = document.createElement("script");

    const scriptText2 = document.createTextNode(
      "!function() { var t; if (t = window.driftt = window.drift = window.driftt || []," +
        " !t.init) return t.invoked ? void (window.console && console.error && console.er" +
        "ror('Drift snippet included twice.')) : (t.invoked = !0,  t.methods = [ 'identif" +
        "y', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', " +
        "'on' ],  t.factory = function(e) { return function() { var n; return n = Array.p" +
        "rototype.slice.call(arguments), n.unshift(e), t.push(n), t; }; }, t.methods.forE" +
        "ach(function(e) { t[e] = t.factory(e); }), t.load = function(t) { var e, n, o, i" +
        "; e = 3e5, i = Math.ceil(new Date() / e) * e, o = document.createElement('script" +
        "'),  o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.sr" +
        "c = 'https://js.driftt.com/include/' + i + '/' + t + '.js',  n = document.getEle" +
        "mentsByTagName('script')[0], n.parentNode.insertBefore(o, n); }); }(); drift.SNI" +
        "PPET_VERSION = '0.3.1'; drift.load('7dutxpneim9g');"
    );

    script2.appendChild(scriptText2);
    document.head.appendChild(script2);
  }
});

registerServiceWorker();
