import React, { Component } from 'react';
import Paper from 'material-ui/Paper';

export default class Card extends Component {
    render() {
        return (
            <Paper style={{marginBottom: 30, boxShadow: "0 1px 5px 0 rgba(0,0,0,.1)", borderRadius: "8px", padding: "20px", ...this.props.style}}>
                <div style={{textAlign: "left", fontFamily: "Avenir Next", fontSize: "14px", fontWeight: 400, color: "#7d7d7d", textDecoration: "none", marginTop: "2px"}}> 
                    {this.props.children}
                </div>
            </Paper>
        )
    }
}