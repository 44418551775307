
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { db } from "../../fire.js";
import firebase from "../../fire.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { Button, Select, Avatar, Input, Upload, Icon, Modal, message, Popconfirm } from "antd";
import { typography } from "material-ui/styles";
import * as Colors from "material-ui/styles/colors";
const { TextArea } = Input;
const Option = Select.Option;

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#002F49",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});


const styles = {
  navigation: {
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: "grey",
    paddingBottom: 15,
    display: "block"
  },
  title: {
    fontSize: "1.2em",
    paddingTop: ".50em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitle: {
    fontSize: "1em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitleTime: {
    fontSize: "0.7em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  subTitle: {
    paddingTop: "0.2em",
    paddingRight: "1.5em",
    paddingBottom: "1.0em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightRegular,
    color: "#666",
    fontFamily: "Avenir Next"
  },
  cardLeft: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "1%",
    width: "24.5%",
    float: "left",
    marginBottom: "1.3em",
    fontFamily: "Avenir Next"
  },
  cardRight: {
    padding: 30,
    marginTop: "1.3em",
    width: "24.5%",
    float: "left",
    fontFamily: "Avenir Next"
  },
  cardBoth: {
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    fontFamily: "Avenir Next"
  },
  proPic: {
    marginTop: 15,
    height: "100%",
    width: "100%"
  },
  rightBlock: {
    float: "left",
    marginLeft: 15,
    paddingBottom: 15
  },
  paperTablet: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    fontFamily: "Avenir Next",
    overflow: "auto",
    borderRadius: "4px"
  },
  paperMobile: {
    marginTop: "2.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "0px"
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  profileInfoCard: {
    padding: 1,
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both",
    borderRadius: "4px"
  },
  paper: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "10px"
  },
  paperGallery: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: 0
  },
  stickDiv: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    position: "sticky",
    top: "20px"
  },
  stickDivSafari: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    top: "20px"
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  accountHeaderItem: {
    paddingTop: "0.3em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightLight,
    color: "#666",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: typography.fontWeightMedium,
    marginTop: 5,
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  caseHeader: {
    fontSize: 15,
    paddingTop: 25,
    display: "flex"
  },
  lineItem: {
    fontSize: 15,
    paddingTop: 25
  },
  lineItemFirst: {
    fontSize: 15,
    paddingTop: 15
  },
  lineTitle: {
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Avenir Next",
    width: "100%",
    color: "#353535",
    paddingBottom: "4px",
    color: "#55360"
  },
  lineValue: {
    fontWeight: 400,
    width: "100%",
    fontSize: "15px",
    whiteSpace: "pre-line",
    color: "#353535"
  },
  verifiedInfo: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  verifiedInfoGray: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
    display: "inline-block",
    verticalAlign: "middle"
  },
  actionCard: {
    padding: 15,
    marginTop: "1.3em",
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both"
  },
  actionCardMobile: {
    padding: 15,
    marginTop: "1.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  proPicSmall: {
    float: "right",
    height: 35,
    marginRight: 10,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  caseIcon: {
    float: "left",
    height: 35,
    marginRight: 10,
    marginTop: 5,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  proPicSmallMobile: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "4px"
  },
  refProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  pocProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  nameLabel: {
    fontSize: "1.2em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    color: "#000000"
  },
  nameLabelMobile: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    widht: "100%",
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  refNameLabel: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  tag: {
    color: "#2f54eb",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
    borderRadius: "4px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: "0.9em",
    display: "inline-block"
  },
  cardHeader: {
    fontSize: 25,
    fontWeight: 600,
    paddingTop: 30,
    paddingLeft: 30,
    backgroundColor: "#ffffff",
    color: "black"
  },
  infoRow: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 30
  }
};

const dummyRequest = ({ file, onSuccess }) => {

  // Create a root feedback
  var storageRef = firebase.storage().ref();

  // Create a feedback to 'mountains.jpg'
  var mountainsRef = storageRef.child("mountains.jpg");

  var randomId =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15);

  var uploadTask = storageRef
    .child("images" + "/" + randomId + ".jpg")
    .put(file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    function (snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
      }
    },
    function (error) {
      // Handle unsuccessful uploads
    },
    function () {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then(
        function (downloadURL) {
          console.log("File available at", downloadURL);
          onSuccess(downloadURL);
        }.bind(this)
      );
    }.bind(this)
  );

};

export class FeedbackCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      changed: {},
      selectedFile: null,
      selectedFileList: []
    }; // <- set up react state
  }

  handleChange = (event, type) => {
    var data = this.state.feedback_data;

    var changed = this.state.changed;

    if (type == "submitter_name") {
      data.submitter_name = event.target.value;
      changed["submitter_name"] = event.target.value;
    }

    if (type == "date_timeframe") {
      data.date_timeframe = event.target.value;
      changed["date_timeframe"] = event.target.value;
    }

    if (type == "proj_summary") {
      data.proj_summary = event.target.value;
      changed["proj_summary"] = event.target.value;
    }

    if (type == "feedback") {
      data.feedback = event.target.value;
      changed["feedback"] = event.target.value;
    }

    if (type == "tips") {
      data.tips = event.target.value;
      changed["tips"] = event.target.value;
    }


    this.setState({ feedback_data: data, changed: changed });
  };

  editCard(event) {
    this.setState({ editing: true });
  }

  cancelEdit(event) {
    var data = this.props.item;

    var copiedObject = Object.assign({}, data);

    this.setState({ feedback_data: copiedObject, editing: false, changed: {} });
  }

  saveCard(event) {
    var data = this.state.feedback_data;
    var changed = this.state.changed;

    console.log("id is =>", this.props.id);
    console.log("item id is =>", this.props.item.id);
    console.log("changed is =>", changed);

    //   var feedback_data = { feedback_data: changed };


    db.collection("profiles")
      .doc(this.props.id)
      .collection("feedback")
      .doc(this.props.item.id)
      .set(changed, { merge: true })
      .then(
        function () {
          console.log("Document successfully written!");
          this.setState({ editing: false, changed: {} });
        }.bind(this)
      )
      .catch(
        function (error) {
          console.error("Error writing document: ", error);
        }.bind(this)
      );



  }

  handleChipChange = (value, type) => {
    var data = this.state.feedback_data;
    var changed = this.state.changed;

    console.log(`selected ${value}`);

    if (type == "tech_used") {
      data.tech_used = value;
      changed["tech_used"] = value;
    }


    this.setState({ feedback_data: data, changed: changed });
  };

  componentDidMount() {
    var data = this.props.item

    /*          var tech = data
                        .speci_tech
                        .join(', '); */

    var copiedObject = Object.assign({}, data);

    this.setState({ feedback_data: copiedObject });
  }

  onChange = info => {

    var data = this.state.feedback_data;
    var changed = this.state.changed;

    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        console.log("info is =>", info.file.response)
        data["image"] = info.file.response;
        changed["image"] = info.file.response;
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState({ feedback_data: data, changed: changed, selectedFile: nextState.selectedFile, selectedFileList: nextState.selectedFileList });
  };

  confirmDelete(e) {
    console.log(e);
    // Add the delete stuff here

    console.log("id is =>", this.props.id);
    console.log("item id is =>", this.props.item);

    db.collection("profiles")
      .doc(this.props.id)
      .collection("feedback")
      .doc(this.props.item.id).delete().then(function () {
        console.log("Document successfully deleted!");
        window.location.reload()
      }).catch(function (error) {
        console.error("Error removing document: ", error);
      });


  }

  cancel(e) {
    console.log(e);
  }

  render() {



    var i = this.props.i
    var item = this.state.feedback_data
    var data = this.props.data

    var tech_used = [];

    if (item == null) {
      return null
    }

    var accountType = this.props.accountType

    if (item.tech_used != null) {
      item.tech_used.forEach(function (t) {
        var queryString = "/partners/?search=" + t;
        tech_used.push(
          <div style={styles.tag}>
            <a
              href={queryString}
              style={{ textDecoration: "none", color: "#2f54eb" }}
            >
              {" "}
              {t}{" "}
            </a>
          </div>
        );
      });
    }

    return (
      <div>

        {!this.state.editing && (

          <div>

            <MediaQuery minWidth={992}>
              <div style={styles.lineItem}>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle"
                    }}
                  >
                    <div style={styles.refNameLabel}>{item.submitter_name}</div>

                    <div style={styles.smallTitle}>{item.date_timeframe}</div>
                  </div>
                </div>

                {/*
                            <div
                                style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                float: "right"
                            }}>

                                <img src={item.client_image} style={styles.refProPicSmall}/>

                        </div> */}
              </div>
            </MediaQuery>
            <MediaQuery minWidth={701} maxWidth={991}>
              <div style={styles.lineItem}>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle"
                    }}
                  >
                    <div style={styles.refNameLabel}>{item.submitter_name}</div>

                    <div style={styles.smallTitle}>{item.date_timeframe}</div>
                  </div>
                </div>

                {/*

                            <div
                                style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                float: "right"
                            }}>

                                <img src={item.client_image} style={styles.refProPicSmall}/>

                        </div> */}
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={700}>
              <div style={styles.lineItem}>
                {/*

                            <div
                                style={{
                                display: "inline-block",
                                verticalAlign: "middle"
                            }}>

                                <img src={item.client_image} style={styles.refProPicSmall}/>

                        </div>  */}

                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                >
                  <div style={styles.refNameLabel}>{item.submitter_name}</div>

                  <div style={styles.smallTitle}>{item.date_timeframe}</div>
                </div>
              </div>
            </MediaQuery>

          </div>

        )}

        {this.state.editing && (
          <div style={{ marginTop: 20 }}>


            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Submitter Name</div>
              <Input
                onChange={e => this.handleChange(e, "submitter_name")}
                value={item.submitter_name}
                placeholder="Submitter Name"
              />
            </div>

            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Date/Timeframe</div>
              <Input
                onChange={e => this.handleChange(e, "date_timeframe")}
                value={item.date_timeframe}
                placeholder="3 months (2018)"
              />
            </div>

            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Project Summary</div>

              <TextArea
                onChange={e => this.handleChange(e, "proj_summary")}
                value={item.proj_summary}
                style={styles.inputStyle}
                placeholder="Short summary of the proj"
                autosize={{ minRows: 2, maxRows: 6 }}
              />
            </div>


            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Our Experience and Feedback</div>

              <TextArea
                onChange={e => this.handleChange(e, "feedback")}
                value={item.feedback}
                placeholder="What feedback do you have?"
                autosize={{ minRows: 2, maxRows: 6 }}
              />

            </div>

            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Tips for working with them</div>

              <TextArea
                onChange={e => this.handleChange(e, "tips")}
                value={item.tips}
                placeholder="Communicate often and early, etc..."
                autosize={{ minRows: 2, maxRows: 6 }}
              />

            </div>



          </div>
        )}


        <div
          style={{
            paddingLeft: 0
          }}
        >

          {!this.state.editing && (
            <div>


              {item.proj_summary != null && item.proj_summary != "" && (
                <div style={styles.lineItem}>
                  <div style={styles.lineTitle}>Project Summary</div>
                  <div style={styles.lineValue}>{item.proj_summary}</div>
                </div>
              )}

              {item.feedback != null && item.feedback != "" && (
                <div style={styles.lineItem}>
                  <div style={styles.lineTitle}>Our Experience and Feedback</div>
                  <div style={styles.lineValue}>{item.feedback}</div>
                </div>
              )}

              {item.tips != null && item.tips != "" && (
                <div style={styles.lineItem}>
                  <div style={styles.lineTitle}>Tips for working with them</div>
                  <div style={styles.lineValue}>{item.tips}</div>
                </div>
              )}


            </div>

          )}

          {accountType == 2 && (
            <div>
              {!this.state.editing && (
                <Button
                  style={{
                    float: "right",
                    marginRight: 20,
                    marginBottom: 5
                  }}
                  onClick={event => this.editCard(event)}
                >
                  Edit
                  </Button>
              )}

              {this.state.editing && (
                <div style={{ marginTop: 20 }}>

                  <div style={{ float: "left" }}>
                    <Popconfirm title="There is no way to recover a case once its deleted, do you wish to proceed?" onConfirm={this.confirmDelete.bind(this)} onCancel={this.cancel} okText="I understand" cancelText="Nevermind">
                      <Button type="danger">Delete Feedback</Button>
                    </Popconfirm>
                  </div>

                  <div style={{ clear: "right" }}> </div>

                  <div style={{ float: "right" }}>
                    <Button
                      style={{ marginRight: 5, marginBottom: 5 }}
                      onClick={event => this.cancelEdit(event)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginRight: 20, marginBottom: 5 }}
                      onClick={event => this.saveCard(event)}
                    >
                      Save
                    </Button>
                  </div>


                </div>
              )}

              <div style={{ clear: "right" }}> </div>
            </div>
          )}



          {i != data.length - 1 && (
            <div
              style={{
                marginTop: 15,
                marginLeft: 0,
                marginRight: -30,
                width: "100%",
                height: 15,
                borderBottom: "1px solid #E4E4E4"
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

export class FeedbacksCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      feedback: []
    }; // <- set up react state
  }

  addProject() {
    console.log("sup bitches")

    const { store, id } = this.props

    var data = this.state.feedback

    var newId = Math
      .random()
      .toString(36)
      .substring(2, 15) + Math
        .random()
        .toString(36)
        .substring(2, 15)

    data.push({ id: newId, submitter_name: "New Submitter" })

    this.setState({ feedback: data });

  }

  componentDidMount() {
    const { store, id } = this.props

    var data = this.props.feedback

    this.setState({ feedback: data });
  }


  render() {
    const { store, id } = this.props;
    var data = this.state.feedback;

    if (data == null) {
      return null;
    }

    var accountType = 0;

    if (store.getState().userData != null) {
      accountType = store.getState().userData.acc_type;
    }

    var items = data.map(function (item, i) {
      return (
        <FeedbackCard item={item} id={id} data={data} i={i} accountType={accountType} />
      );
    });
    if (accountType == 2) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <div style={styles.cardHeader}>Feedback</div>

            <div style={styles.infoRow}>
              {items}


              {items.length == 0 && (
                <div>
                  <div
                    style={{
                      display: "table",
                      margin: "0 auto",
                      textAlign: "center"
                    }}
                  >
                    <img
                      src="https://cdn.dribbble.com/users/1058271/screenshots/3308818/sadpaper_2x.png"
                      style={{
                        width: "50%",
                        paddingTop: 30
                      }}
                    />
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "1.5em",
                        color: "#a7a7a7de",
                        paddingBottom: 5
                      }}
                    >
                      No Feedback Yet :(
                  </div>

                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.8em",
                        color: "#a7a7a7de",
                        paddingBottom: 50
                      }}
                    >
                      Shoot us a message if you need feedback verified on your
                      behalf!
                  </div>
                  </div>
                </div>
              )}


              {accountType == 2 && (
                <div style={{ marginTop: 20 }}>
                  <Button
                    type="primary"
                    style={{
                      float: "right",
                      marginRight: 20,
                      marginBottom: 5
                    }}
                    onClick={event => this.addProject(event)}
                  >
                    Add Feedback
            </Button>
                </div>
              )}

              <div style={{ clear: "right" }}> </div>


            </div>
          </div>
          <div id="feedback" />
        </MuiThemeProvider>
      );
    }
    else {
      return null;
    }
  }
}