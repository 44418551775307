import React, {Component} from 'react';
import * as Colors from 'material-ui/styles/colors';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import '../App.css';
import Paper from 'material-ui/Paper';
import {typography} from 'material-ui/styles';
import RaisedButton from 'material-ui/RaisedButton';
import MediaQuery from 'react-responsive';
import firebase from '../fire.js';
import {logoutUser} from '../actions/auth'
import {startImportHistory} from '../actions/pay'
import {TopBar} from '../App';

import { connect } from "react-redux";

import { db } from "../fire.js";

import {styles} from '../styles.js';

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "material-ui-icons/Close";
import { OutlineButton, Card } from './Objects'
import { Link } from 'react-router-dom';

import { Button, Select, Avatar } from 'antd';

// Initialize Cloud Firestore through Firebase

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#002F49",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});




// Initialize Cloud Firestore through Firebase

const accTypeStruct = {
  0: "DevPay Client Account",
  1: "DevPay Vendor Account",
  2: "DevPay Admin Account"
};


const mapStateToProps = state => ({
  ...state,
  projects: state.projects,
  currentUser: state.currentUser,
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => ({
  onLoad: userId => {
   // dispatch(fetchTransactions(userId));
  },
  logOut: () => {
    dispatch(logoutUser());
    firebase.auth().signOut();
  }
});

export class Account extends Component {
  componentDidMount() {
    if (this.props.currentUser) {
     // this.props.onLoad(this.props.currentUser.id);
      
    }
  }

  handleClick(event) {
    const {store} = this.props
    store.dispatch(logoutUser())
    firebase
      .auth()
      .signOut()
      
      window.location.replace("/");

   

  }

  render() {

    const {store} = this.props

    var data = store
      .getState()
      .userData

    if (data) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <div>
              <div className="invoicePage">
                <div style={{textAlign: "left", fontFamily: "Avenir Next", color: "#000000", marginBottom: "20px"}}> <Link to="/" style={{textDecoration: "none", color: "#7c7c7c"}}> Home </Link> / Account Settings </div>
                <AccountInfoCard
                  currentUser={data}
                />
                <div>
                  <a
                    style={{
                      color: "#ababab",
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                      float: "right",
                      marginTop: "0.3em",
                      marginBottom: 30,
                      cursor: "pointer"
                    }}
                    onClick={event => this.handleClick(event)}
                  >
                    Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </MuiThemeProvider>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}
  
export class AccountInfoCard extends Component {
  constructor() {
    super();

    this.state = {
      waterMarkOn: false,
      currentUser: {
        name: "",
        billing_info: {
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zip: ""
        }
      }
    };
    this.saveBillingInfo = this.saveBillingInfo.bind(this)
  }

  handleClick = message => {
    this.setState({ open: true, message: message });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  handleChange = (event, type) => {
    var currentUser = this.state.currentUser;

    currentUser[type] = event.target.value

    this.setState({ currentUser: currentUser });
  };

  imageUpload = e => {
    var currentUser = this.state.currentUser;

    var file = e.target.files[0];
    getBase64(file).then(base64 => {
      localStorage["fileBase64"] = base64;
    });

    // Create a root reference
    var storageRef = firebase.storage().ref();

    // Create a reference to 'mountains.jpg'
    var mountainsRef = storageRef.child("mountains.jpg");

    var randomId =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    var uploadTask = storageRef
      .child(this.state.currentUser.id + "/" + randomId + ".jpg")
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
      },
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(
          function(downloadURL) {
            console.log("File available at", downloadURL);
            this.handleClick("Image Ready");
            currentUser["image"] = downloadURL;
            this.setState({ currentUser: currentUser });
          }.bind(this)
        );
      }.bind(this)
    );
  };

  saveBillingInfo = event => {
    var currentUser = this.props.currentUser;

    db.collection("users")
      .doc(currentUser.id)
      .set(currentUser, { merge: true })
      .then(
        function() {
          console.log("Document successfully updated!");
        //  this.handleClick("Account Info Saved!");

        window.location.reload();


        }.bind(this)
      )
      .catch(
        function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          this.handleClick("Error Saving Account Info!");
        }.bind(this)
      );
  };

  componentDidMount() {
    var currentUser = this.props.currentUser;

    if (currentUser.billing_info == null) {
      currentUser.billing_info = {};
    }

    this.setState({ currentUser: currentUser });
  }

  showUploadWaterMark() {
    this.setState({waterMarkOn: !this.state.waterMarkOn})
  }

  render() {
    var currentUser = this.state.currentUser;
    var billing_info = currentUser.billing_info;

    var name = this.state.currentUser.name
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

         <div
                    style={{
                    fontSize: 16,
                    fontWeight: 400,
                    paddingBottom: 15, 
                    color: "#4e4e4e",
                    fontFamily: "Brown",
                    fontWeight: 500,
                    textAlign: "left"
                }}>
                    Account Info
                </div>

  

            <Card>


               <div style={{fontSize: "17px",fontWeight: 500, fontFamily: "Avenir Next", marginBottom: "7px"}}>
                  Account Image
                </div>

                <label for="imageFile"  style={{cursor: "pointer"}}>
                  <input
                  style={{display: "none"}}
                  type="file"
                  id="imageFile"
                  name="imageFile"
                  onChange={this.imageUpload}
                />
                  {(currentUser.image != null && currentUser.image != "") &&
                  <img src={currentUser.image} style={{width: 100, height: 100, borderRadius: "50%"}}/>
                  }

                  {(currentUser.image == null || currentUser.image == "") &&
                   <Avatar size={64} style={{ color: '#f56a00', backgroundColor: '#fde3cf', verticalAlign: 'middle' }}>{initials}</Avatar>
                  }

              </label>



              <div style={{fontSize: "17px", marginTop: "20px", fontWeight: 500, fontFamily: "Avenir Next"}}>
                 Your Name
                </div>
                
                <input value={currentUser.name} style={styles.lineTextBox} onInput={(e) => this.handleChange(e, "name") } />

                 <div style={{fontSize: "17px", marginTop: "20px", fontWeight: 500, fontFamily: "Avenir Next"}}>
                 Company Name
                </div>
                
                <input value={currentUser.company} style={styles.lineTextBox} onInput={(e) => this.handleChange(e, "company") } />



          
      

              <div style={{ marginTop: "15px", display: "inline-block", float: "right"}}>

<OutlineButton name={"Save Info"} action={this.saveBillingInfo} color={"#555EEE"} />

</div>

<div style={{clear: "right"}}> </div>

      
        </Card>
      </div>
    );
  }
}

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);