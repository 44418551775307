import React, { Component } from 'react';
import * as Colors from 'material-ui/styles/colors';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { TopBar } from '../App';
import '../App.css';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import { typography } from 'material-ui/styles';
import TextField from 'material-ui/TextField';
import { Step, Stepper, StepButton, StepContent } from 'material-ui/Stepper';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import MediaQuery from 'react-responsive';
import firebase, { auth, db } from '../fire.js';
import { fetchResultsForQuery } from '../actions/search'
import ContentLoader from 'react-content-loader'
import { Helmet } from "react-helmet";
import Card from './Card';
import { Link } from 'react-router-dom'

import { Button, Select, Avatar, Icon } from 'antd';

const MyLoader = () => (
  <ContentLoader
    height={200}
    width={400}
    speed={2}
    primaryColor={"#f3f3f3"}
    secondaryColor={"#ecebeb"}>
    <rect x="9" y="9" rx="5" ry="5" width="70" height="70" />
    <rect x="89.59" y="13.05" rx="4" ry="4" width="117" height="11.05" />
    <rect x="92.3" y="30.05" rx="0" ry="0" width="66.2" height="10" />
    <rect x="91.3" y="47.05" rx="0" ry="0" width="66" height="11" />
    <rect x="14.3" y="87.05" rx="0" ry="0" width="61" height="13" />
    <rect x="15.3" y="107.05" rx="0" ry="0" width="240" height="11" />
    <rect x="14.3" y="124.05" rx="0" ry="0" width="243" height="14" />
    <rect x="14.3" y="148.05" rx="0" ry="0" width="66" height="14" />
    <rect x="16.3" y="170.05" rx="0" ry="0" width="265" height="15" />
  </ContentLoader>
)

const styles = {
  name: {
    fontSize: '1.2em',
    fontWeight: typography.fontWeightMedium,
    marginTop: ".70em",
    marginRight: "0.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500
  },
  location: {
    paddingTop: "0.1em",
    fontSize: '0.8em',
    fontWeight: typography.fontWeightRegular,
    color: '#666',
    fontFamily: "Avenir Next",
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  bio: {
    paddingTop: "0.2em",
    paddingRight: "1.5em",
    paddingBottom: "1.0em",
    fontSize: '1.0em',
    fontWeight: typography.fontWeightRegular,
    color: '#666',
    fontFamily: "Avenir Next"
  },
  paper: {
    padding: 5,
    marginRight: "1em",
    marginRight: "20%",
    marginLeft: "20%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    height: "100%"
  },
  paperDevInfo: {
    padding: 5,
    marginRight: "1em",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    height: "100%",
    width: "260px"
  },
  paperDevResults: {
    padding: 5,
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    flex: 1,
    height: "100%"
  },
  paperDevInfoMobile: {
    padding: 5,
    marginTop: "1.3em",
    marginLeft: "2%",
    marginRight: "2%",
    fontFamily: "Avenir Next",
    borderRadius: "4px"
  },
  paperDevResultsMobile: {
    padding: 5,
    marginTop: "1.3em",
    marginLeft: "2%",
    marginRight: "2%",
    fontFamily: "Avenir Next",
    borderRadius: "4px"
  },
  searchBox: {
    height: 60,
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 10,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "20%",
    marginRight: "20%",
    marginBottom: "1.2em",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center"
  },
  searchBoxBlank: {
    height: 60,
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 10,
    paddingBottom: 0,
    width: "40em",
    marginBottom: "1.2em",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    margin: "auto"
  },
  searchBoxBlankTablet: {
    height: 60,
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 10,
    paddingBottom: 0,
    width: "35em",
    marginBottom: "1.2em",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    margin: "auto"
  },
  searchBoxBlankMobile: {
    height: 60,
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 10,
    paddingBottom: 0,
    marginLeft: "4%",
    marginRight: "4%",
    marginBottom: "1.2em",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center"
  },
  logo: {
    width: '100%',
    margin: 'auto',
    paddingTop: "5em",
    paddingBottom: "2em",
    fontFamily: "Avenir Next"
  },
  searchBoxMobile: {
    height: 60,
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 10,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: "1.2em",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center"
  },
  resultsRow: {
    fontSize: '0.20em',
    fontFamily: "Avenir Next"
  },
  resultsHeaderStyle: {
    fontSize: '0.50em'
  },
  filterCard: {
    marginLeft: "15%",
    width: "22%",
    float: 'left',
    fontFamily: "Avenir Next",
    position: 'sticky',
    top: 15,
    borderRadius: "4px",
    overflow: "hidden"
  },
  filterCardMobile: {
    marginTop: "1.3em",
    marginLeft: "2%",
    marginRight: "2%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  lineItem: {
    fontSize: 15,
    paddingTop: 25
  },
  lineItemFirst: {
    fontSize: 15,
    paddingTop: 2
  },
  lineTitle: {
    fontWeight: 400,
    fontFamily: 'Avenir',
    width: '100%',
    color: "#C1C3C6",
    paddingBottom: "4px"
  },
  lineValue: {
    fontWeight: 400,
    width: '100%'
  },
  icon: {
    height: 13.5,
    width: 13.5,
    marginRight: 10,
    display: "inline-block",
    verticalAlign: "middle"
  },
  verifiedInfo: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  verifiedInfoGray: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
    display: "inline-block",
    verticalAlign: "middle"
  },
  smallTitleTime: {
    fontSize: '0.7em',
    fontWeight: typography.fontWeightRegular,
    color: '#AEADAD',
    fontFamily: "Avenir Next",
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  tag: {
    color: "#2f54eb",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
    borderRadius: "4px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: "0.8em",
    display: "inline-block"
  },
  tagHighlighted: {
    color: "#fa8c16",
    background: "#fff7e6",
    borderColor: "#ffe58f",
    border: "1px solid #ffe58f",
    borderRadius: "4px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: "0.8em",
    display: "inline-block"
  },
  thinBlue: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#dcdcdc",
    color: "#2263ff",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    marginRight: 5
  },
  moreIcon: {
    height: 11,
    width: 11,
    marginLeft: 5,
    display: "inline-block",
    verticalAlign: "middle"
  },
  thickPurp: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    marginRight: "10px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "rgb(1, 40, 72)",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none"
  },
  noBorder: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    color: "#5f6aff",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none"
  },
  exploreBox: {
    width: 286,
    display: "inline-block", marginRight: 22.5, marginLeft: 22.5,
    marginBottom: 40
  }
};

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#4C6BFF",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});


class ExploreBoxes extends Component {

  twasClicked(event) {
    console.log("well fook " + event)

    window.amplitude.getInstance().logEvent("SEARCH_CATEGORY_PRESSED", { 'category': event });
    return false
  }

  render() {
    return (
      <div>
        <MediaQuery minWidth={1360}>
          <div style={{ width: "100%", marginTop: 100, marginBottom: 0, textAlign: "center" }}>

            <a href="?search=ukraine,%20russia" onClick={this.twasClicked.bind(this, "eastern_europe")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/easterneurope.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=ios" onClick={this.twasClicked.bind(this, "ios")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/ios.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=india" onClick={this.twasClicked.bind(this, "india")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/india.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=web" onClick={this.twasClicked.bind(this, "web")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/web.png')}
                style={styles.exploreBox} /> </a>

          </div>
          <div style={{ width: "100%", marginTop: 0, marginBottom: 100, textAlign: "center" }}>

            <a href="?search=android" onClick={this.twasClicked.bind(this, "android")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/android.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=argentina,%20uruguay" onClick={this.twasClicked.bind(this, "south_america")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/southamerica.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=vr,%20ar" onClick={this.twasClicked.bind(this, "vr_ar")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/vrar.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=design" onClick={this.twasClicked.bind(this, "design")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/design.png')}
                style={styles.exploreBox} /> </a>

          </div>
        </MediaQuery>
        <MediaQuery minWidth={1021} maxWidth={1359}>
          <div style={{ width: "100%", marginTop: 100, marginBottom: 0, textAlign: "center" }}>

            <a href="?search=ukraine,%20russia" onClick={this.twasClicked.bind(this, "eastern_europe")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/easterneurope.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=ios" onClick={this.twasClicked.bind(this, "ios")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/ios.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=india" onClick={this.twasClicked.bind(this, "india")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/india.png')}
                style={styles.exploreBox} /> </a>

          </div>
          <div style={{ width: "100%", marginTop: 0, marginBottom: 0, textAlign: "center" }}>

            <a href="?search=web" onClick={this.twasClicked.bind(this, "web")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/web.png')}
                style={styles.exploreBox} /> </a>


            <a href="?search=android" onClick={this.twasClicked.bind(this, "android")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/android.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=argentina,%20uruguay" onClick={this.twasClicked.bind(this, "south_america")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/southamerica.png')}
                style={styles.exploreBox} /> </a>

          </div>
          <div style={{ width: "100%", marginTop: 0, marginBottom: 100, textAlign: "center" }}>

            <a href="?search=vr,%20ar" onClick={this.twasClicked.bind(this, "vr_ar")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/vrar.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=design" onClick={this.twasClicked.bind(this, "design")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/design.png')}
                style={styles.exploreBox} /> </a>

          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1020}>
          <div style={{ width: "100%", marginTop: 100, marginBottom: 0, textAlign: "center" }}>

            <a href="?search=ukraine,%20russia" onClick={this.twasClicked.bind(this, "eastern_europe")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/easterneurope.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=ios" onClick={this.twasClicked.bind(this, "ios")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/ios.png')}
                style={styles.exploreBox} /> </a>

          </div>
          <div style={{ width: "100%", marginTop: 0, marginBottom: 0, textAlign: "center" }}>

            <a href="?search=india" onClick={this.twasClicked.bind(this, "india")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/india.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=web" onClick={this.twasClicked.bind(this, "web")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/web.png')}
                style={styles.exploreBox} /> </a>

          </div>
          <div style={{ width: "100%", marginTop: 0, marginBottom: 0, textAlign: "center" }}>

            <a href="?search=android" onClick={this.twasClicked.bind(this, "android")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/android.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=argentina,%20uruguay" onClick={this.twasClicked.bind(this, "south_america")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/southamerica.png')}
                style={styles.exploreBox} /> </a>

          </div>
          <div style={{ width: "100%", marginTop: 0, marginBottom: 100, textAlign: "center" }}>

            <a href="?search=vr,%20ar" onClick={this.twasClicked.bind(this, "vr_ar")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/vrar.png')}
                style={styles.exploreBox} /> </a>

            <a href="?search=design" onClick={this.twasClicked.bind(this, "design")} style={{ textDecoration: "none" }}>
              <img
                src={require('../images/design.png')}
                style={styles.exploreBox} /> </a>

          </div>
        </MediaQuery>
      </div>
    )
  }

}

export class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      query: "",
      enterPressed: false
    }; // <- set up react state
  }

  componentWillMount() { }

  componentDidMount() {
    const { store } = this.props

    this.unsubscribe = store.subscribe(() => this.forceUpdate())

    if (this.props.query.search != "" && this.props.query.search != null && store.getState().searchResults == null) {

      //   this.setState({query: this.props.query.search, enterPressed: true})
      // this.handleQuery();
      this.setState({ query: this.props.query.search, enterPressed: true })
      store.dispatch(fetchResultsForQuery(this.props.query.search))
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  handleQuery(event) {
    const { store } = this.props

    if (this.state.query.replace(/\s/g, "") != "") {
      store.dispatch(fetchResultsForQuery(this.state.query))
    }
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code == 13) {


      window.amplitude.getInstance().logEvent("SEARCH_INITIATED", { 'query': this.state.query });

      window.location.assign("?search=" + this.state.query)
    }
  }



  render() {
    const { store } = this.props


    if (this.state.enterPressed == false) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>



          <MediaQuery minWidth={992}>

            <Helmet>
              <title>Aloa Partner Search</title>
              <meta name="description" content={"Aloa Partner Search is a curated list of the best non-US development firms. Work with iOS, Android and Web development talent abroad."} />
            </Helmet>

            <div style={styles.logo}>
              <img
                src={require('../images/aloa_small_line.png')}
                style={{
                  height: "60px",
                  paddingTop: 10,
                  display: "block",
                  margin: "auto"
                }} />
            </div>

            <Card style={styles.searchBoxBlank}>
              <TextField
                type="text"
                name="fname"
                style={{
                  width: "80%",
                  height: 40,
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  fontSize: "1.2em",
                  paddingLeft: 30,
                  flex: 1
                }}
                onChange={(event, newValue) => this.setState({
                  ...this.state,
                  query: newValue
                })}
                onKeyPress={this
                  .enterPressed
                  .bind(this)}
                underlineStyle={{
                  display: 'none'
                }}
                placeholder="Search by technology, country, firm name, etc..." />

              <img
                src={require('../images/searchbar_icon.svg')}
                style={{
                  height: 20,
                  width: 20,
                  float: "right",
                  flex: 0.1
                }} />
            </ Card>


            <ExploreBoxes />


          </ MediaQuery>
          <MediaQuery minWidth={701} maxWidth={991}>

            <div style={styles.logo}>
              <img
                src={require('../images/aloa_small_line.png')}
                style={{
                  height: "60px",
                  paddingTop: 10,
                  display: "block",
                  margin: "auto"
                }} />
            </div>

            <Card style={styles.searchBoxBlankTablet}>
              <TextField
                type="text"
                name="fname"
                style={{
                  width: "80%",
                  height: 40,
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  fontSize: "1.2em",
                  paddingLeft: 30,
                  flex: 1
                }}
                onChange={(event, newValue) => this.setState({
                  ...this.state,
                  query: newValue
                })}
                onKeyPress={this
                  .enterPressed
                  .bind(this)}
                underlineStyle={{
                  display: 'none'
                }}
                placeholder="Search by technology, country, firm name, etc..." />

              <img
                src={require('../images/searchbar_icon.svg')}
                style={{
                  height: 20,
                  width: 20,
                  float: "right",
                  flex: 0.1
                }} />
            </ Card>

            <ExploreBoxes />

          </ MediaQuery>
          <MediaQuery maxWidth={700}>

            <div style={styles.logo}>
              <img
                src={require('../images/aloa_small_line.png')}
                style={{
                  height: "60px",
                  paddingTop: 10,
                  display: "block",
                  margin: "auto"
                }} />
            </div>

            <Card style={styles.searchBoxBlankMobile}>
              <TextField
                type="text"
                name="fname"
                style={{
                  width: "80%",
                  height: 40,
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  fontSize: "1.2em",
                  paddingLeft: 5,
                  flex: 1
                }}
                onChange={(event, newValue) => this.setState({
                  ...this.state,
                  query: newValue
                })}
                onKeyPress={this
                  .enterPressed
                  .bind(this)}
                underlineStyle={{
                  display: 'none'
                }}
                placeholder="Technology, country, firm name... " />

              <img
                src={require('../images/searchbar_icon.svg')}
                style={{
                  height: 20,
                  width: 20,
                  float: "right",
                  flex: 0.1
                }} />
            </ Card>


            <ExploreBoxes />

          </ MediaQuery>

        </MuiThemeProvider>

      )
    }

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>



          <MediaQuery minWidth={992}>

            <Card style={styles.searchBox}>
              <TextField
                type="text"
                name="fname"
                style={{
                  width: "80%",
                  height: 40,
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  fontSize: "1.2em",
                  paddingLeft: 30,
                  flex: 1
                }}
                onChange={(event, newValue) => this.setState({
                  ...this.state,
                  query: newValue
                })}
                value={this.state.query}
                onKeyPress={this
                  .enterPressed
                  .bind(this)}
                underlineStyle={{
                  display: 'none'
                }}
                placeholder="Search" />

              <img
                src={require('../images/searchbar_icon.svg')}
                style={{
                  height: 20,
                  width: 20,
                  float: "right",
                  flex: 0.1
                }} />
            </ Card>

            <ResultsTable store={store} />
          </ MediaQuery>
          <MediaQuery minWidth={701} maxWidth={991}>

            <Card style={styles.searchBoxMobile}>
              <TextField
                type="text"
                name="fname"
                style={{
                  width: "80%",
                  height: 40,
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  fontSize: "1.2em",
                  paddingLeft: 30,
                  flex: 1
                }}
                onChange={(event, newValue) => this.setState({
                  ...this.state,
                  query: newValue
                })}
                value={this.state.query}
                onKeyPress={this
                  .enterPressed
                  .bind(this)}
                underlineStyle={{
                  display: 'none'
                }}
                placeholder="Search" />

              <img
                src={require('../images/searchbar_icon.svg')}
                style={{
                  height: 20,
                  width: 20,
                  float: "right",
                  flex: 0.1
                }} />
            </ Card>

            <ResultsTable store={store} />

          </ MediaQuery>
          <MediaQuery maxWidth={700}>

            <Card style={styles.searchBoxMobile}>
              <TextField
                type="text"
                name="fname"
                style={{
                  width: "80%",
                  height: 40,
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  fontSize: "1.2em",
                  paddingLeft: 30,
                  flex: 1
                }}
                onChange={(event, newValue) => this.setState({
                  ...this.state,
                  query: newValue
                })}
                value={this.state.query}
                onKeyPress={this
                  .enterPressed
                  .bind(this)}
                underlineStyle={{
                  display: 'none'
                }}
                placeholder="Search" />

              <img
                src={require('../images/searchbar_icon.svg')}
                style={{
                  height: 20,
                  width: 20,
                  float: "right",
                  flex: 0.1
                }} />
            </ Card>

            <ResultsTable store={store} />

          </ MediaQuery>
          <div style={{
            paddingTop: 45
          }} />
        </div>
      </ MuiThemeProvider>
    )
  };
}

export class ResultCardMobile extends Component {

  twasClicked(event, location) {
    console.log("sooook " + event)

    window.amplitude.getInstance().logEvent("VIEW_PROFILE_CLICKED", { 'firm': event, 'location': location });
    return false
  }

  render() {
    const { store } = this.props

    var profile = this.props.profile
    var highlightResult = profile._highlightResult

    var company_info = profile.company_info
    var health = profile.health


    var speci_tech = company_info
      .speci_tech
      .join(', ');
    var speci_ind = company_info
      .speci_industry
      .join(', ');

    var url = "profile/" + profile.objectID
    var firmId = profile.objectID

    var date = new Date()

    var techInResults = false
    var offeringsInResults = false
    var indInResults = false
    var devLocationsInResults = false
    var addServicesInResults = false

    var devLocations = []
    var offerings = []
    var tech = []
    var ind = []
    var add_services = []

    var date = new Date()

    highlightResult
      .process_info
      .dev_locations
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          devLocations.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          devLocationsInResults = true
          devLocations.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })

    highlightResult
      .company_info
      .offerings
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          offerings.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          offeringsInResults = true
          offerings.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })

    highlightResult
      .company_info
      .speci_industry
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          ind.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          indInResults = true
          ind.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })

    {
      highlightResult.process_info.add_services != null && highlightResult
        .process_info
        .add_services
        .forEach(function (t) {

          var div = document.createElement("div");
          div.innerHTML = t.value;
          var text = div.textContent || div.innerText || "";

          var queryString = "?search=" + text

          if (t.matchLevel == "none") {
            add_services.push(
              <div style={styles.tag}>
                <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
              </div>
            )
          }

          if (t.matchLevel != "none") {
            addServicesInResults = true
            add_services.push(
              <div style={styles.tagHighlighted}>
                <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
              </div>
            )
          }

        })
    }

    highlightResult
      .company_info
      .speci_tech
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          tech.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          techInResults = true
          tech.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })

    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          <div style={{
            display: 'flex'
          }}>
            <div
              style={{
                marginRight: 10,
                marginLeft: 15,
                width: 60,
                marginTop: 15
              }}>
              <a href={url} onClick={this.twasClicked.bind(this, firmId, "mobile_profile_pic")}><img
                src={company_info.image}
                style={{
                  height: 60,
                  width: 60
                }} /></a>
            </div>
            <div>
              <div style={styles.name}>
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'black'
                  }}
                  href={url}
                  onClick={this.twasClicked.bind(this, firmId, "mobile_name_label")}
                  dangerouslySetInnerHTML={{
                    __html: highlightResult.company_info.name.value
                  }}></a>
              </div>
              <div
                style={styles.location}
                dangerouslySetInnerHTML={{
                  __html: highlightResult.company_info.hq.value
                }}></div>

            </div>
          </ div>

          <div
            style={{
              paddingLeft: 15,
              paddingBottom: 15,
              paddingRight: 15
            }}>

            <div style={styles.lineItemFirst}>
              <div style={styles.lineTitle}>About</div>
              <div
                style={styles.lineValue}
                dangerouslySetInnerHTML={{
                  __html: highlightResult.company_info.short_bio.value
                }}></div>
            </div>

            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Offerings</div>
              <div style={styles.lineValue}>
                {offerings}
              </div>
            </div>

            {techInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Technology Specilization</div>
              <div style={styles.lineValue}>
                {tech}
              </div>
            </div>
            }

            {indInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Client Specialization (Industry)</div>
              <div style={styles.lineValue}>
                {ind}
              </div>
            </div>
            }

            {devLocationsInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Developer Locations</div>
              <div style={styles.lineValue}>
                {devLocations}
              </div>
            </div>
            }

            {addServicesInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Additional Services</div>
              <div style={styles.lineValue}>
                {add_services}
              </div>
            </div>
            }


          </div>

        </ MuiThemeProvider>
      </ div>
    )
  };
}

export class ResultCardInfo extends Component {

  twasClicked(event, location) {
    console.log("sooook " + event)

    window.amplitude.getInstance().logEvent("VIEW_PROFILE_CLICKED", { 'firm': event, 'location': location });
    return false
  }



  render() {
    const { store } = this.props

    var profile = this.props.profile
    var highlightResult = profile._highlightResult

    var company_info = profile.company_info
    var health = profile.health

    var url = "profile/" + profile.objectID
    var firmId = profile.objectID

    var isFlat = false
    var lowest_rate = 1000
    var highest_rate = 0
    var flat_rate = 0

    var date = new Date()

    if (company_info.rates != null) {

      Object.keys(company_info.rates).forEach(function (key) {
        console.log(key, company_info.rates[key]);
        if (company_info.rates[key] < lowest_rate) {
          lowest_rate = company_info.rates[key]
        }

        if (company_info.rates[key] > highest_rate) {
          highest_rate = company_info.rates[key]
        }

        if (key == "flat") {
          isFlat = true
          flat_rate = company_info.rates[key]
        }
      });
    }

    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          <div style={{
            display: 'flex',
            whiteSpace: "nowrap"
          }}>
            <div
              style={{
                marginRight: 10,
                marginLeft: 15,
                width: 60,
                marginTop: 15,
                whiteSpace: "nowrap"
              }}>
              <a href={url} onClick={this.twasClicked.bind(this, firmId, "desktop_profile_pic")}><img
                src={company_info.image}
                style={{
                  height: 60,
                  width: 60
                }} /></a>
            </div>
            <div>
              <div style={styles.name}>
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'black'
                  }}
                  href={url}
                  onClick={this.twasClicked.bind(this, firmId, "desktop_name_label")}
                  dangerouslySetInnerHTML={{
                    __html: highlightResult.company_info.name.value
                  }}></a>
              </div>
              <div
                style={styles.location}
                dangerouslySetInnerHTML={{
                  __html: highlightResult.company_info.hq.value
                }}></div>

            </div>
          </ div>

          <div
            style={{
              paddingLeft: 15,
              paddingBottom: 15,
              paddingRight: 15
            }}>

            {/*

          {(company_info.rates != null && isFlat == false) && <div style={{
    fontSize: 15,
    paddingTop: 1
  }}>
              <div style={styles.lineTitle}>Rate</div>
              <div style={styles.lineValue}>${lowest_rate} - ${highest_rate} / hour</div>
          </div>}

          {(company_info.rates != null && isFlat == true) && <div style={{
    fontSize: 15,
    paddingTop: 10
  }}>
              <div style={styles.lineTitle}>Rate</div>
              <div style={styles.lineValue}>${flat_rate} / hour [flat rate]</div>
          </div>}

*/}



            {(health != null) && <div
              style={{
                marginTop: "1em",
                maxWidth: '100%',
                color: "#0085ff",
                whiteSpace: "nowrap"
              }}>

              {(health.ver_ref_count == 0 || health.ver_ref_count == null) && <div style={styles.verifiedInfoGray}>
                <img src={require('../images/notapproved.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  0 Verified References
                                </div>
              </div>
              }

              {(health.ver_case_count == 0 || health.ver_case_count == null) && <div style={styles.verifiedInfoGray}>
                <img src={require('../images/notapproved.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  <a href={url + "#work"} style={{ textDecoration: "none", color: "#000000" }}>
                    0 Verified Case Studies
                                    </a>
                </div>
              </div>
              }

              {(health.ver_case_count != 0 && health.ver_case_count != null) && <div style={styles.verifiedInfo}>
                <img src={require('../images/approve_blue.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  {health.ver_case_count} Verified Case Studies
                                </div>
              </div>
              }

              {(health.ver_ref_count != 0 && health.ver_ref_count != null) && <div style={styles.verifiedInfo}>
                <img src={require('../images/approve_blue.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  {health.ver_ref_count} Verified References
                                </div>
              </div>
              }

              {(health.di_tested == false || health.di_tested == null)
                && <div style={styles.verifiedInfoGray}>
                  <img src={require('../images/notapproved.svg')} style={styles.icon} />
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle"
                    }}>
                    Aloa Certified
                                </div>
                </div>
              }

              {(health.di_tested == true && health.di_tested != null) && <div style={styles.verifiedInfo}>
                <img src={require('../images/approve_blue.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  Aloa Certified
                                </div>
              </div>
              }

            </div>
            }

            {(health == null) && <div
              style={{
                marginTop: "1em",
                maxWidth: '100%',
                color: "#0085ff"
              }}>
              <div style={styles.verifiedInfoGray}>
                <img src={require('../images/notapproved.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  0 Verified References
                                </div>
              </div>

              <div style={styles.verifiedInfoGray}>
                <img src={require('../images/notapproved.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  0 Verified Case Studies
                                </div>
              </div>

              <div style={styles.verifiedInfoGray}>
                <img src={require('../images/notapproved.svg')} style={styles.icon} />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}>
                  Aloa Certified
                                </div>
              </div>
            </div>
            }


          </div>
        </ MuiThemeProvider>
      </ div>
    )
  }
}

export class ResultCardHighlighted extends Component {

  twasClicked(event, location) {
    console.log("sooook " + event)

    window.amplitude.getInstance().logEvent("VIEW_PROFILE_CLICKED", { 'firm': event, 'location': location });
    return false
  }

  render() {
    const { store } = this.props

    var profile = this.props.profile
    var highlightResult = profile._highlightResult

    var company_info = profile.company_info
    var health = profile.health

    console.log("offending is " + company_info.name)


    var speci_tech = company_info
      .speci_tech
      .join(', ');
    var speci_ind = company_info
      .speci_industry
      .join(', ');

    var url = "profile/" + profile.objectID
    var firmId = profile.objectID

    var date = new Date()

    var techInResults = false
    var offeringsInResults = false
    var indInResults = false
    var devLocationsInResults = false
    var addServicesInResults = false

    var devLocations = []
    var offerings = []
    var tech = []
    var ind = []
    var add_services = []

    console.log("highlightResult is =>", highlightResult)

    if (highlightResult.process_info != null) {

      highlightResult
        .process_info
        .dev_locations
        .forEach(function (t) {

          var div = document.createElement("div");
          div.innerHTML = t.value;
          var text = div.textContent || div.innerText || "";

          var queryString = "?search=" + text

          if (t.matchLevel == "none") {
            devLocations.push(
              <div style={styles.tag}>
                <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
              </div>
            )
          }

          if (t.matchLevel != "none") {
            devLocationsInResults = true
            devLocations.push(
              <div style={styles.tagHighlighted}>
                <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
              </div>
            )
          }

        })

      highlightResult.process_info.add_services != null && highlightResult
        .process_info
        .add_services
        .forEach(function (t) {

          var div = document.createElement("div");
          div.innerHTML = t.value;
          var text = div.textContent || div.innerText || "";

          var queryString = "?search=" + text

          if (t.matchLevel == "none") {
            add_services.push(
              <div style={styles.tag}>
                <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
              </div>
            )
          }

          if (t.matchLevel != "none") {
            addServicesInResults = true

            add_services.push(
              <div style={styles.tagHighlighted}>
                <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
              </div>
            )
          }

        })

    }



    highlightResult
      .company_info
      .speci_industry
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          ind.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          indInResults = true
          ind.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })

    highlightResult
      .company_info
      .offerings
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          offerings.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          offeringsInResults = true
          offerings.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })





    highlightResult
      .company_info
      .speci_tech
      .forEach(function (t) {

        var div = document.createElement("div");
        div.innerHTML = t.value;
        var text = div.textContent || div.innerText || "";

        var queryString = "?search=" + text

        if (t.matchLevel == "none") {
          tech.push(
            <div style={styles.tag}>
              <a href={queryString} style={{ textDecoration: "none", color: "#2f54eb" }}> {text} </a>
            </div>
          )
        }

        if (t.matchLevel != "none") {
          techInResults = true
          tech.push(
            <div style={styles.tagHighlighted}>
              <a href={queryString} style={{ textDecoration: "none", color: "#fa8c16" }}> {text} </a>
            </div>
          )
        }

      })

    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>

          <div
            style={{
              paddingLeft: 15,
              paddingBottom: 15,
              paddingRight: 15
            }}>

            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>About</div>
              <div
                style={styles.lineValue}
                dangerouslySetInnerHTML={{
                  __html: highlightResult.company_info.short_bio.value
                }}></div>
            </div>

            <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Offerings</div>
              <div style={styles.lineValue}>
                {offerings}
              </div>
            </div>

            {techInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Technology Specilization</div>
              <div style={styles.lineValue}>
                {tech}
              </div>
            </div>
            }

            {indInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Client Specialization (Industry)</div>
              <div style={styles.lineValue}>
                {ind}
              </div>
            </div>
            }

            {devLocationsInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Developer Locations</div>
              <div style={styles.lineValue}>
                {devLocations}
              </div>
            </div>
            }

            {addServicesInResults && <div style={styles.lineItem}>
              <div style={styles.lineTitle}>Additional Services</div>
              <div style={styles.lineValue}>
                {add_services}
              </div>
            </div>
            }



            <div style={{ float: "right", paddingTop: "1.5em", paddingBottom: "1em" }}> <a href={url} onClick={this.twasClicked.bind(this, firmId, "view_more_button")} style={{ textDecoration: "none" }} >

              <Button type="primary">
                View Profile<Icon type="arrow-right" />
              </Button>

            </a>

            </div>

            {/*
<div style={{float: "right", paddingTop: "1.5em", paddingBottom: "1em"}}> <a href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSeeyvGkYwNz05WZYgVkUrulfH6CNlzPCT4znTDHA1bSKHjNTA/viewform?usp=send_form"  target="_blank" style={{textDecoration: "none"}} > <div style={styles.thickPurp}><div style={{display: "inline-block",
      verticalAlign: "middle"}}> Get Quote </div>  </div> </a> 

</div> */}

          </div>
        </ MuiThemeProvider>
      </ div>
    )
  };
}

export class LoadingResults extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          <MyLoader />
        </MuiThemeProvider>
      </ div>
    )
  }
}

export class NoResults extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          <div
            style={{
              display: "table",
              margin: "0 auto",
              textAlign: "center"
            }}>
            <img
              src={require('../images/no-results.png')}
              style={{
                width: "50%",
                paddingTop: 30
              }} />
            <div
              style={{
                textAlign: "center",
                fontSize: '1.5em',
                color: "#a7a7a7de",
                paddingBottom: 5
              }}>
              No Results :(
            </div>

            <div
              style={{
                textAlign: "center",
                fontSize: '0.8em',
                color: "#a7a7a7de",
                paddingBottom: 50
              }}>
              Try altering your search criteria
            </div>

          </div>
        </MuiThemeProvider>
      </ div>
    )
  }
}

export class ResultsTable extends Component {

  render() {
    const { store } = this.props

    if (store.getState().searchResults == null) {

      return (
        <div>
          <MuiThemeProvider muiTheme={muiTheme}>
            <Card style={styles.paper}>
              <LoadingResults />
            </ Card>

            <Card style={styles.paper}>
              <LoadingResults />
            </ Card>

            <Card style={styles.paper}>
              <LoadingResults />
            </ Card>
          </MuiThemeProvider >
        </div>
      )
    }

    var profiles = store
      .getState()
      .searchResults
      .data

    var finished = store
      .getState()
      .searchResults
      .finished

    if (profiles == null) {

      return (
        <div>
          <MuiThemeProvider muiTheme={muiTheme}>
            <Card style={styles.paper}>
              <LoadingResults />
            </ Card>

            <Card style={styles.paper}>
              <LoadingResults />
            </ Card>

            <Card style={styles.paper}>
              <LoadingResults />
            </ Card>
          </MuiThemeProvider >
        </div>
      )
    }

    if ((profiles == null || profiles.length == 0) && finished == true) {

      return (
        <div>
          <MuiThemeProvider muiTheme={muiTheme}>

            <MediaQuery minWidth={992}>
              <Card style={styles.paper}>

                <NoResults />
              </ Card>
            </ MediaQuery>
            <MediaQuery minWidth={701} maxWidth={991}>
              <Card style={styles.paperMobile}>

                <NoResults />
              </ Card>

            </ MediaQuery>
            <MediaQuery maxWidth={700}>
              <Card style={styles.paperMobile}>

                <NoResults />
              </ Card>

            </ MediaQuery>

          </MuiThemeProvider >
        </div>
      )
    }

    if ((profiles == null || profiles.length == 0) && finished == false) {

      return (
        <div>
          <MuiThemeProvider muiTheme={muiTheme}>

            <MediaQuery minWidth={992}>
              <Card style={styles.paper}>
                <LoadingResults />
              </ Card>

              <Card style={styles.paper}>
                <LoadingResults />
              </ Card>

              <Card style={styles.paper}>
                <LoadingResults />
              </ Card>
            </ MediaQuery>
            <MediaQuery minWidth={701} maxWidth={991}>
              <Card style={styles.paperMobile}>
                <LoadingResults />
              </ Card>

              <Card style={styles.paperMobile}>
                <LoadingResults />
              </ Card>

              <Card style={styles.paperMobile}>
                <LoadingResults />
              </ Card>

            </ MediaQuery>
            <MediaQuery maxWidth={700}>
              <Card style={styles.paperMobile}>
                <LoadingResults />
              </ Card>

              <Card style={styles.paperMobile}>
                <LoadingResults />
              </ Card>

              <Card style={styles.paperMobile}>
                <LoadingResults />
              </ Card>

            </ MediaQuery>

          </MuiThemeProvider >
        </div>
      )
    }

    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          {profiles.map((row, index) => (row.company_info && <div>
            <MediaQuery minWidth={992}>

              <div
                style={{
                  display: "flex",
                  marginLeft: "20%",
                  marginRight: "20%",
                  marginBottom: "1em"
                }}>

                <Card style={{ display: "flex", width: "100%", fontFamily: "Avenir Next", padding: "5px" }}>


                  <div style={{ flex: 0.5, borderRight: "1px solid rgb(237, 237, 237)", margin: 20 }}>
                    <ResultCardInfo profile={row} store={store} />
                  </ div>

                  <div style={{ flex: 1 }}>
                    <ResultCardHighlighted profile={row} store={store} />

                  </ div>


                </Card>




              </div>

            </ MediaQuery>

            <MediaQuery minWidth={701} maxWidth={991}>
              <Card style={styles.paperDevInfoMobile}>
                <ResultCardMobile profile={row} store={store} />
              </ Card>
            </ MediaQuery>
            <MediaQuery maxWidth={700}>
              <Card style={styles.paperDevInfoMobile}>
                <ResultCardMobile profile={row} store={store} />
              </ Card>

            </ MediaQuery>
          </ div>))}

        </MuiThemeProvider>
      </div>
    )
  };
}