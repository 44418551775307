import * as firebase from 'firebase';
import 'firebase/firestore';

var config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyB0_NP-6ucUW8nPe2DVrA7pB6YhWs-9MM8",
    authDomain: "devindex-b628b.firebaseapp.com",
    databaseURL: "https://devindex-b628b.firebaseio.com",
    projectId: "devindex-b628b",
    storageBucket: "",
    messagingSenderId: "821420475285",
    storageBucket: "devindex-b628b.appspot.com"
};

firebase.initializeApp(config);

firebase.firestore().enablePersistence()
.then(function() {
    // Initialize Cloud Firestore through firebase
    var db = firebase.firestore();
    console.log("got firebase!")
})
.catch(function(err) {
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log("error, multiple tabs open!")
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log("error, current browser doesn't support")
    }
});

export const db = firebase.firestore();
export const auth = firebase.auth();
export default firebase;