import React, { Component } from "react";
import * as Colors from "@material-ui/core/colors";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import "../Auth.css";
import { typography } from "material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Target } from "react-popper";
import firebase, { auth, db } from "../fire.js";
import { loginUser, logoutUser } from "../actions/auth";
import MediaQuery from "react-responsive";
import { Card } from "./Objects";
import {
  Form,
  Input,
  Icon,
  Button,
  Select,
  message,
  Modal,
  Row,
  Col,
  Upload
} from "antd";

const styles = {
  title: {
    height: 30,
    fontSize: "18em",
    fontWeight: 400,
    paddingTop: ".70em",
    fontFamily: "Avenir Next",
    fontWeight: 500
  },
  smallTitle: {
    paddingTop: "0.4em",
    height: 30,
    fontSize: "1.0em",
    fontWeight: 200,
    color: "#666",
    fontFamily: "Avenir Next"
  },
  subTitle: {
    paddingTop: "0.2em",
    height: 30,
    fontSize: "1.1em",
    fontWeight: 200,
    color: "#666",
    fontFamily: "Avenir Next"
  },
  logo: {
    margin: "auto",
    padding: "0em 0em 0.5em 0em",
    fontFamily: "Avenir Next"
  },
  paper: {
    width: "300px",
    padding: "2em 3em 3em 3em",
    fontFamily: "Avenir Next"
  },
  paperMobile: {
    marginLeft: "4%",
    marginRight: "4%",
    padding: "2em 3em 3em 3em",
    fontFamily: "Avenir Next"
  },
  paperTablet: {
    width: "400px",
    margin: "auto",
    padding: "2em 3em 3em 3em",
    fontFamily: "Avenir Next"
  },
  loginButton: {
    marginTop: "3em",
    width: "100%"
  }
};

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#002F49",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempEmail: "",
      tempPassword: "",
      forgotPass: false,
      loading: false
    }; // <- set up react state
  }

  handleClick(event) {
    const { store } = this.props;

    this.setState({ loading: true });

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.tempEmail, this.state.tempPassword)
      .catch(
        function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // alert(errorMessage)
          message.error(errorMessage);
          this.setState({ loading: false });
          // ...
        }.bind(this)
      );

    firebase.auth().onAuthStateChanged(
      function(user) {
        if (user) {
          this.setState({ loading: false });
          store.dispatch(loginUser(user));
          // ...
        } else {
          // User is signed out. ...
        }
      }.bind(this)
    );
  }

  handlePassReset(event) {
    const { store } = this.props;

    this.setState({ loading: true });

    firebase
      .auth()
      .sendPasswordResetEmail(this.state.tempResetEmail)
      .then(
        function() {
          // Email sent.
          alert("Reset Email Sent!");
          this.forgotPass();
        }.bind(this)
      )
      .catch(
        function(error) {
          // An error happened.
          var errorCode = error.code;
          var errorMessage = error.message;
          message.error(errorMessage);
          this.setState({ loading: false });
        }.bind(this)
      );
  }

  forgotPass() {
    this.setState({ forgotPass: !this.state.forgotPass });
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.handleClick(event);
    }
  }

  handleChange = (event, type) => {
    if (type == "email") {
      this.setState({
        tempEmail: event.target.value,
        tempPassword: this.state.tempPassword
      });
    }

    if (type == "reset_email") {
      this.setState({ tempResetEmail: event.target.value });
    }

    if (type == "password") {
      this.setState({
        tempEmail: this.state.tempEmail,
        tempPassword: event.target.value
      });
    }
  };

  render() {
    var bgImage =
      "https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=8baa4175fc826de805f96bc428c653a0&auto=format&fit=crop&w=1567&q=80";
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div class="box">
          <Card style={styles.paper}>
            <div style={styles.logo}>
              <img
                src={require("../images/aloa_small_line.png")}
                style={{
                  height: "45px",
                  paddingTop: 10,
                  display: "block",
                  margin: "auto"
                }}
              />
            </div>

            {this.state.forgotPass == false && (
              <div style={{ marginTop: 0 }}>
                <TextField
                  label="Email"
                  placeholder="example@gmail.com"
                  style={{ width: "100%" }}
                  margin="normal"
                  onChange={e => this.handleChange(e, "email")}
                  value={this.state.tempEmail}
                  onKeyPress={this.enterPressed.bind(this)}
                />
                <br />

                <TextField
                  label="Password"
                  placeholder="Not password123"
                  style={{ width: "100%" }}
                  margin="normal"
                  type="password"
                  onChange={e => this.handleChange(e, "password")}
                  value={this.state.tempPassword}
                  onKeyPress={this.enterPressed.bind(this)}
                />
                <br />

                <Button
                  variant="contained"
                  type="submit"
                  primary={true}
                  style={{
                    backgroundColor: "#0080fd",
                    color: "#ffffff",
                    width: "100%",
                    marginTop: "20px"
                  }}
                  loading={this.state.loading}
                  onClick={event => this.handleClick(event)}
                >
                  Login
                </Button>

                <div
                  style={{
                    paddingTop: "1.5em",
                    textAlign: "center"
                  }}
                >
                  <a
                    onClick={event => this.forgotPass()}
                    style={{
                      color: "#000000",
                      cursor: "pointer"
                    }}
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>
            )}

            {this.state.forgotPass == true && (
              <div style={{ marginTop: 0 }}>
                <div style={{ marginTop: 20 }}>
                  No worries, we'll send you a link to reset it.
                </div>

                <TextField
                  label="Email"
                  placeholder="example@gmail.com"
                  style={{ width: "100%" }}
                  margin="normal"
                  onChange={e => this.handleChange(e, "reset_email")}
                  value={this.state.tempResetEmail}
                />
                <br />

                <Button
                  variant="contained"
                  type="submit"
                  primary={true}
                  style={{
                    backgroundColor: "#545def",
                    color: "#ffffff",
                    width: "100%",
                    marginTop: "20px"
                  }}
                  onClick={event => this.handlePassReset(event)}
                >
                  Reset Password
                </Button>

                <div
                  style={{
                    paddingTop: "1.5em",
                    textAlign: "center"
                  }}
                >
                  <a
                    onClick={event => this.forgotPass()}
                    style={{
                      color: "#000000",
                      cursor: "pointer"
                    }}
                  >
                    Nevermind, I remember
                  </a>
                </div>
              </div>
            )}
          </Card>
        </div>
      </MuiThemeProvider>
    );
  }
}
