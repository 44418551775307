import React, {Component} from 'react';
import * as Colors from 'material-ui/styles/colors';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import '../App.css';
import Paper from 'material-ui/Paper';
import {typography} from 'material-ui/styles';
import RaisedButton from 'material-ui/RaisedButton';
import MediaQuery from 'react-responsive';
import firebase from '../fire.js';
import {logoutUser} from '../actions/auth'
import {startImportHistory} from '../actions/pay'
import {TopBar} from '../App';

const muiTheme = getMuiTheme({
    palette: {
      textColor: Colors.darkBlack,
      primary1Color: "#002F49",
      primary2Color: Colors.indigo700,
      accent1Color: Colors.redA200,
      pickerHeaderColor: Colors.white,
      alternateTextColor: "#ffffff"
    },
    appBar: {
      height: 60
    }
  });


export class NotFound extends Component {
    render() {
        return(
            <MuiThemeProvider muiTheme={muiTheme}>



        <div>

             <div style={{textAlign: "center"}}> 
                        <a href="/">
                    <img src={require('../images/404.png')} style={{width: "40%", position: "relative", top: 0, left: 0}} /> 
                    
                    <div style={{fontSize: "30px", fontFamily: "Avenir"}}>
                        Nothing here! (click to go home)
                        </div>
                    
                    </a> </div> 
                    

            </div>

        </MuiThemeProvider>
        )
    }
}