import React, { Component } from "react";
import * as Colors from "material-ui/styles/colors";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import "../App.css";
import "../Profile.css";
import Paper from "material-ui/Paper";
import { typography } from "material-ui/styles";
import MediaQuery from "react-responsive";
import { db } from "../fire.js";
import firebase from "../fire.js";
import { makePayment } from "../actions/pay";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import BrowserDetection from "react-browser-detection";
import Checkbox from "material-ui/Checkbox";
import "./DevProfile.css";
import { TopBar } from "../App";
import Card from "./Card";
import { CasesCard } from "./Profile/Cases"
import { ReferencesCard } from "./Profile/References"
import { FeedbacksCard } from "./Profile/Feedback"
import { CompanyInfoCard } from "./Profile/CompanyInfoCard"
import { ProcessInfoCard } from "./Profile/OtherInfoCard"
import { GalleryCard } from "./Profile/GalleryCard"

import { Button, Select, Avatar, Input, Upload, Icon, Modal, message, Popconfirm } from "antd";

const { TextArea } = Input;
const Option = Select.Option;

// Initialize Cloud Firestore through Firebase
const buttonStyle = {
  thinBlue: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#dcdcdc",
    color: "#2263ff",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    marginRight: 5
  },
  thinGreen: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#dcdcdc",
    color: "#0dbf0d",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    marginRight: 5
  },
  thinSkypePurp: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#00aff0",
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    color: "#00aff0",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thinJoin: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 1,
    fontWeight: 500,
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#F0F2F6",
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    color: "#6094F7",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thinQuoteGreen: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#12dc91",
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    color: "#12dc91",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickBlue: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "rgb(19, 30, 53)",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickSkype: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#00aff0",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickSkypePurp: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#00aff0",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickGreen: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#02d44c",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none"
  },
  thickRed: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#fd658f",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none"
  },
  icon: {
    height: 11,
    width: 11,
    marginRight: 5,
    display: "inline-block",
    verticalAlign: "middle"
  }
};

const styles = {
  navigation: {
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: "grey",
    paddingBottom: 15,
    display: "block"
  },
  title: {
    fontSize: "1.2em",
    paddingTop: ".50em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitle: {
    fontSize: "1em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  smallTitleTime: {
    fontSize: "0.7em",
    fontWeight: typography.fontWeightRegular,
    color: "#AEADAD",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  subTitle: {
    paddingTop: "0.2em",
    paddingRight: "1.5em",
    paddingBottom: "1.0em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightRegular,
    color: "#666",
    fontFamily: "Avenir Next"
  },
  cardLeft: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "1%",
    width: "24.5%",
    float: "left",
    marginBottom: "1.3em",
    fontFamily: "Avenir Next"
  },
  cardRight: {
    padding: 30,
    marginTop: "1.3em",
    width: "24.5%",
    float: "left",
    fontFamily: "Avenir Next"
  },
  cardBoth: {
    marginTop: "1.3em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    fontFamily: "Avenir Next"
  },
  proPic: {
    marginTop: 15,
    height: "100%",
    width: "100%"
  },
  rightBlock: {
    float: "left",
    marginLeft: 15,
    paddingBottom: 15
  },
  paperTablet: {
    padding: 30,
    marginTop: "1.3em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    fontFamily: "Avenir Next",
    overflow: "auto",
    borderRadius: "4px"
  },
  paperMobile: {
    marginTop: "2.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "0px"
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "25%",
    marginRight: "25%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  profileInfoCard: {
    padding: 1,
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both",
    borderRadius: "4px"
  },
  paper: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: "10px"
  },
  paperGallery: {
    marginTop: "2.3em",
    marginLeft: "490px",
    marginRight: "170px",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden",
    padding: 0
  },
  stickDiv: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    position: "sticky",
    top: "20px"
  },
  stickDivSafari: {
    marginLeft: "170px",
    width: "260px",
    marginRight: "60px",
    float: "left",
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    top: "20px"
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: "both",
    overflow: "auto",
    borderRadius: "4px"
  },
  accountHeaderItem: {
    paddingTop: "0.3em",
    fontSize: "1.0em",
    fontWeight: typography.fontWeightLight,
    color: "#666",
    fontFamily: "Avenir Next",
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: typography.fontWeightMedium,
    marginTop: 5,
    wordWrap: "break-word",
    maxWidth: "100%"
  },
  caseHeader: {
    fontSize: 15,
    paddingTop: 25,
    display: "flex"
  },
  lineItem: {
    fontSize: 15,
    paddingTop: 25
  },
  lineItemFirst: {
    fontSize: 15,
    paddingTop: 15
  },
  lineTitle: {
    fontWeight: 400,
    fontSize: "15px",
    fontFamily: "Avenir Next",
    width: "100%",
    color: "#353535",
    paddingBottom: "4px",
    color: "#55360"
  },
  lineValue: {
    fontWeight: 400,
    width: "100%",
    fontSize: "15px",
    whiteSpace: "pre-line",
    color: "#353535"
  },
  verifiedInfo: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  verifiedInfoGray: {
    height: "30px",
    width: "100%",
    fontSize: "1em",
    color: "#000000",
    position: "relative",
    top: 0,
    userSelect: "none",
    textDecoration: "none",
    marginRight: 5,
    marginBottom: 5
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
    display: "inline-block",
    verticalAlign: "middle"
  },
  actionCard: {
    padding: 15,
    marginTop: "1.3em",
    width: "100%",
    float: "left",
    fontFamily: "Avenir Next",
    clear: "both"
  },
  actionCardMobile: {
    padding: 15,
    marginTop: "1.3em",
    marginLeft: "5%",
    marginRight: "5%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  proPicSmall: {
    float: "right",
    height: 35,
    marginRight: 10,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  caseIcon: {
    float: "left",
    height: 35,
    marginRight: 10,
    marginTop: 5,
    borderRadius: "4px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  proPicSmallMobile: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "4px"
  },
  refProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  pocProPicSmall: {
    float: "left",
    height: 35,
    marginRight: 10,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  nameLabel: {
    fontSize: "1.2em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    color: "#000000"
  },
  nameLabelMobile: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    widht: "100%",
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  refNameLabel: {
    fontSize: "1.5em",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    lineHeight: "normal",
    wordWrap: "break-word",
    maxWidth: "100%",
    color: "#000000"
  },
  tag: {
    color: "#2f54eb",
    background: "#f0f5ff",
    borderColor: "#adc6ff",
    borderRadius: "4px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 5,
    marginTop: 5,
    fontSize: "0.9em",
    display: "inline-block"
  },
  cardHeader: {
    fontSize: 25,
    fontWeight: 600,
    paddingTop: 30,
    paddingLeft: 30,
    backgroundColor: "#ffffff",
    color: "black"
  },
  infoRow: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 30
  }
};

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#002F49",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});

export class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      platforms: [],
      existing: null,
      timeframe: null,
      projectDesc: null,
      email: null,
      name: null,
      firm: this.props.firm.name,
      timestamp: Date()
    }; // <- set up react state
  }

  nextStep() {
    var eventName = "NEXT_STEP_" + this.state.step;
    window.amplitude.getInstance().logEvent(eventName);
    this.setState(state => ({ step: this.state.step + 1 }));
  }

  prevStep() {
    var eventName = "PREV_STEP_" + this.state.step;
    window.amplitude.getInstance().logEvent(eventName);
    this.setState(state => ({ step: this.state.step - 1 }));
  }

  existingProject(existing) {
    this.setState(state => ({ existing: existing }));
  }

  updateTimeFrame(timeframe) {
    this.setState(state => ({ timeframe: timeframe }));
  }

  updateProjectDesc(event) {
    console.log("oh value is " + event.target.value);
    var test = event.target.value;
    this.setState(state => ({ projectDesc: test }));
  }

  updateEmail(event) {
    var test = event.target.value;
    this.setState(state => ({ email: test }));
  }

  updateName(event) {
    var test = event.target.value;
    this.setState(state => ({ name: test }));
  }

  platformChecked(platform) {
    console.log("FUCK " + platform);
    this.setState(state => ({
      platforms: [...this.state.platforms, platform]
    }));

    if (this.state.platforms.indexOf(platform) > -1) {
      var index = this.state.platforms.indexOf(platform);

      var platformsTemp = this.state.platforms;

      platformsTemp.splice(index, 1);
      this.setState({ platforms: platformsTemp });
    }
  }

  finishClicked() {
    console.log(this.state);

    window.amplitude.getInstance().logEvent("REQUEST_QUOTE_COMPLETE");

    db.collection("quote_requests")
      .add(this.state)
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    this.props.handler();
  }

  cancelClicked() {
    window.amplitude.getInstance().logEvent("REQUEST_QUOTE_CANCEL");
    this.props.handler();
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div class="signUpContainer">
          <div class="signUpBox">
            <div style={{ overflow: "auto", width: "100%", height: "80%" }}>
              {this.state.step == 0 && (
                <div>
                  <p
                    style={{
                      fontSize: "30px",
                      fontFamily: "Avenir",
                      fontWeight: 600
                    }}
                  >
                    {" "}
                    What are you trying to build?{" "}
                  </p>

                  <div>
                    <label class="container">
                      iOS App
                      <input
                        type="checkbox"
                        onChange={this.platformChecked.bind(this, "ios-app")}
                        checked={this.state.platforms.indexOf("ios-app") > -1}
                      />
                      <span class="checkmark" />
                    </label>

                    <label class="container">
                      Android App
                      <input
                        type="checkbox"
                        onChange={this.platformChecked.bind(
                          this,
                          "android-app"
                        )}
                        checked={
                          this.state.platforms.indexOf("android-app") > -1
                        }
                      />
                      <span class="checkmark" />
                    </label>

                    <label class="container">
                      Website
                      <input
                        type="checkbox"
                        onChange={this.platformChecked.bind(this, "web-app")}
                        checked={this.state.platforms.indexOf("web-app") > -1}
                      />
                      <span class="checkmark" />
                    </label>
                  </div>
                </div>
              )}

              {this.state.step == 1 && (
                <div>
                  <p
                    style={{
                      fontSize: "30px",
                      fontFamily: "Avenir",
                      fontWeight: 600
                    }}
                  >
                    {" "}
                    Is this a new or existing project?{" "}
                  </p>

                  <div>
                    <label class="container">
                      New Project
                      <input
                        type="radio"
                        name="radio"
                        onChange={this.existingProject.bind(this, false)}
                        checked={
                          !this.state.existing && this.state.existing != null
                        }
                      />
                      <span class="checkmark" />
                    </label>

                    <label class="container">
                      Existing Project
                      <input
                        type="radio"
                        name="radio"
                        onChange={this.existingProject.bind(this, true)}
                        checked={this.state.existing}
                      />
                      <span class="checkmark" />
                    </label>
                  </div>
                </div>
              )}

              {this.state.step == 2 && (
                <div>
                  <p
                    style={{
                      fontSize: "30px",
                      fontFamily: "Avenir",
                      fontWeight: 600
                    }}
                  >
                    {" "}
                    How soon are you trying to start?{" "}
                  </p>

                  <div>
                    <label class="container">
                      Now
                      <input
                        type="radio"
                        name="radio"
                        onChange={this.updateTimeFrame.bind(this, "Now")}
                        checked={this.state.timeframe == "Now"}
                      />
                      <span class="checkmark" />
                    </label>

                    <label class="container">
                      1 Week
                      <input
                        type="radio"
                        name="radio"
                        onChange={this.updateTimeFrame.bind(this, "1 Week")}
                        checked={this.state.timeframe == "1 Week"}
                      />
                      <span class="checkmark" />
                    </label>

                    <label class="container">
                      1 Month
                      <input
                        type="radio"
                        name="radio"
                        onChange={this.updateTimeFrame.bind(this, "1 Month")}
                        checked={this.state.timeframe == "1 Month"}
                      />
                      <span class="checkmark" />
                    </label>

                    <label class="container">
                      Over 1 month
                      <input
                        type="radio"
                        name="radio"
                        onChange={this.updateTimeFrame.bind(
                          this,
                          "Over 1 month"
                        )}
                        checked={this.state.timeframe == "Over 1 month"}
                      />
                      <span class="checkmark" />
                    </label>
                  </div>
                </div>
              )}

              {this.state.step == 3 && (
                <div>
                  <p
                    style={{
                      fontSize: "30px",
                      fontFamily: "Avenir",
                      fontWeight: 600
                    }}
                  >
                    {" "}
                    What are you trying to build?{" "}
                  </p>

                  <div>
                    <textarea
                      rows="4"
                      cols="50"
                      class="descField"
                      value={this.state.projectDesc}
                      onChange={this.updateProjectDesc.bind(this)}
                      placeholder="Just a few words describing your projet"
                    />
                  </div>
                </div>
              )}

              {this.state.step == 4 && (
                <div>
                  <p
                    style={{
                      fontSize: "30px",
                      fontFamily: "Avenir",
                      fontWeight: 600
                    }}
                  >
                    {" "}
                    Who should we send the quote to?{" "}
                  </p>

                  <div>
                    <input
                      type="text"
                      id="lname"
                      value={this.state.name}
                      onChange={this.updateName.bind(this)}
                      class="emailField"
                      name="lastname"
                      placeholder="Name"
                    />

                    <input
                      type="text"
                      id="lname"
                      value={this.state.email}
                      onChange={this.updateEmail.bind(this)}
                      class="emailField"
                      name="lastname"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
              )}

              {this.state.step == 5 && (
                <div>
                  <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <img
                      src={require("../images/getting.png")}
                      style={{ width: "30%" }}
                    />
                    <div
                      style={{
                        fontSize: "20px",
                        fontFamily: "Avenir",
                        fontWeight: 400,
                        marginTop: "20px"
                      }}
                    >
                      Your request has been sent to {this.props.firm.name}!
                    </div>
                  </div>
                </div>
              )}
            </div>

            {this.state.step != 5 && (
              <p
                style={{
                  fontSize: "13px",
                  fontFamily: "Avenir",
                  fontWeight: 300,
                  marginTop: 0
                }}
              >
                {" "}
                *Requesting quote from {this.props.firm.name}{" "}
              </p>
            )}

            <div class="bottomRow">
              <div
                onClick={event => this.cancelClicked(event)}
                style={{
                  float: "left",
                  padding: "11px 32px",
                  color: "#cecece",
                  cursor: "pointer"
                }}
              >
                Cancel
              </div>

              <div style={{ float: "right" }}>
                {this.state.step > 0 && this.state.step < 5 && (
                  <div
                    class="backButton"
                    onClick={event => this.prevStep(event)}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      Back
                    </div>
                  </div>
                )}

                {this.state.step < 4 && (
                  <div
                    class="nextButton"
                    onClick={event => this.nextStep(event)}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      Next
                    </div>
                  </div>
                )}

                {this.state.step == 4 && (
                  <div
                    class="nextButton"
                    onClick={event => this.nextStep(event)}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      Finish
                    </div>
                  </div>
                )}

                {this.state.step == 5 && (
                  <div
                    class="nextButton"
                    onClick={event => this.finishClicked(event)}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      Close
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteFormActive: false
    }; // <- set up react state

    this.toggleQuoteForm = this.toggleQuoteForm.bind(this);
  }

  componentWillMount() {
    const { store } = this.props;

    var accountType = 0;

    if (store.getState().userData != null) {
      accountType = store.getState().userData.acc_type;
    }

    var id = this.props.devId;

    var tempProfile = {};
    var tempReferences = [];
    var tempFeedback = [];
    var tempCaseStudies = [];

    var profilesRef = db.collection("profiles").doc(id);

    profilesRef
      .get()
      .then(
        function (doc) {
          if (doc.exists) {
            tempProfile = doc.data();
            this.setState(state => ({ exists: true }));
          } else {
            this.setState(state => ({ exists: false }));
          }

          console.log("images is");
          console.log(tempProfile);
          this.setState(state => ({
            id: tempProfile.id,
            images: tempProfile.images,
            contact: tempProfile.contact,
            company_info: tempProfile.company_info,
            point_of_contact: tempProfile.point_of_contact,
            process_info: tempProfile.process_info,
            health: tempProfile.health
          }));
        }.bind(this)
      )
      .catch(function (error) { });

    profilesRef
      .collection("references")
      .get()
      .then(
        function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var tempData = doc.data()
            tempData["id"] = doc.id

            tempReferences.push(tempData);
          });

          this.setState(state => ({ references: tempReferences }));
        }.bind(this)
      )
      .catch(function (error) { });

    profilesRef
      .collection("case_studies")
      .get()
      .then(
        function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var tempData = doc.data()
            tempData["id"] = doc.id

            tempCaseStudies.push(tempData);
          });

          this.setState(state => ({ case_studies: tempCaseStudies }));
        }.bind(this)
      )
      .catch(function (error) { });

    profilesRef
      .collection("feedback")
      .get()
      .then(
        function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var tempData = doc.data()
            tempData["id"] = doc.id

            tempFeedback.push(tempData);
          });

          this.setState(state => ({ feedback: tempFeedback }));
        }.bind(this)
      )
      .catch(function (error) { });
  }

  toggleQuoteForm(event) {
    this.setState(state => ({ quoteFormActive: !this.state.quoteFormActive }));
  }

  render() {
    const { store } = this.props;

    console.log("printing the store!");
    console.log(this.state.id);

    var data = store.getState().userData;

    var accountType = 0;

    if (data != null) {
      accountType = store.getState().userData.acc_type;
    }

    /*
    var contacts = [];

    console.log("contact is =>", this.state.contact)

    if (this.state.contact != null && accountType == 2) {

      contacts.push(
        <Card style={styles.actionCard}>
          <POCCard store={store} contact={this.state.contact} />
        </Card>
      );

    }
    */

    const browserHandler = {
      safari: () => (
        <div style={styles.stickDivSafari}>
          <Card style={styles.profileInfoCard}>
            <HeaderCard
              store={store}
              health={this.state.health}
              company_info={this.state.company_info}
              references={this.state.references}
              cases={this.state.case_studies}
              handler={this.toggleQuoteForm}
            />
          </Card>

        </div>
      ),
      default: browser => (
        <div style={styles.stickDiv}>
          <Card style={{ padding: "10px" }}>
            <HeaderCard
              store={store}
              health={this.state.health}
              company_info={this.state.company_info}
              references={this.state.references}
              cases={this.state.case_studies}
              handler={this.toggleQuoteForm}
            />
          </Card>
        </div>
      )
    };

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        {this.state.exists == false && (
          <div style={{ textAlign: "center" }}>
            <a href="/">
              <img
                src={require("../images/404.png")}
                style={{ width: "40%", position: "relative", top: 0, left: 0 }}
              />

              <div style={{ fontSize: "30px", fontFamily: "Avenir" }}>
                No profile here! (click to go home)
              </div>
            </a>{" "}
          </div>
        )}

        {this.state.quoteFormActive == true && (
          <div>
            <SignUpForm
              firm={this.state.company_info}
              handler={this.toggleQuoteForm}
            />
          </div>
        )}

        {(this.state.company_info != null && this.state.company_info.name != null) && (
          <Helmet>

            <title>{this.state.company_info.name} Reviews and References</title>
            <meta
              name="description"
              content={
                "Case studies reviews and references for " +
                this.state.company_info.name +
                " | " +
                this.state.company_info.short_bio
              }
            />
          </Helmet>
        )}

        <div>
          <MediaQuery minWidth={992}>
            {this.state.company_info != null && (

              <div class="home">
                <BrowserDetection>{browserHandler}</BrowserDetection>

                {((this.state.images != null && this.state.images.length != 0) || accountType == 2) && (
                  <Card style={styles.paperGallery}>
                    <GalleryCard id={this.props.devId} images={this.state.images} store={store} />
                  </Card>
                )}

                {this.state.health != null && (
                  <Card style={styles.paper}>
                    <CompanyInfoCard
                      id={this.props.devId}
                      company_info={this.state.company_info}
                      contact={this.state.contact}
                      health={this.state.health}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.case_studies != null && (
                  <Card style={styles.paper}>
                    <CasesCard
                      id={this.props.devId}
                      case_studies={this.state.case_studies}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.references != null && (
                  <Card style={styles.paper}>
                    <ReferencesCard
                      id={this.props.devId}
                      references={this.state.references}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.feedback != null && (this.state.feedback.length != 0 || accountType == 2) && (
                  <Card style={styles.paper}>
                    <FeedbacksCard id={this.props.devId} feedback={this.state.feedback} store={store} />
                  </Card>
                )}


                <Card style={styles.paper}>
                  <ProcessInfoCard
                    id={this.props.devId}
                    process_info={this.state.process_info}
                    store={store}
                  />
                </Card>


              </div>
            )}
          </MediaQuery>
          <MediaQuery minWidth={701} maxWidth={991}>
            {this.state.company_info != null && (
              <div>
                <div>
                  <Card style={styles.paperMobile}>
                    <HeaderCard
                      store={store}
                      health={this.state.health}
                      company_info={this.state.company_info}
                      references={this.state.references}
                      cases={this.state.case_studies}
                      handler={this.toggleQuoteForm}
                    />
                  </Card>

                </div>

                {((this.state.images != null && this.state.images.length != 0) || accountType == 2) && (
                  <Paper style={styles.paperMobile}>
                    <GalleryCard id={this.props.devId} images={this.state.images} store={store} />
                  </Paper>
                )}

                {this.state.health != null && (
                  <Card style={styles.paperMobile}>
                    <CompanyInfoCard
                      id={this.props.devId}
                      contact={this.state.contact}
                      company_info={this.state.company_info}
                      health={this.state.health}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.case_studies != null && (
                  <Card style={styles.paperMobile}>
                    <CasesCard
                      id={this.props.devId}
                      case_studies={this.state.case_studies}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.references != null && (
                  <Card style={styles.paperMobile}>
                    <ReferencesCard
                      id={this.props.devId}
                      references={this.state.references}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.feedback != null && (this.state.feedback.length != 0 || accountType == 2) && (
                  <Card style={styles.paperMobile}>
                    <FeedbacksCard id={this.props.devId} feedback={this.state.feedback} store={store} />
                  </Card>
                )}

                <Card style={styles.paperMobile}>
                  <ProcessInfoCard
                    id={this.props.devId}
                    process_info={this.state.process_info}
                    store={store}
                  />
                </Card>
              </div>
            )}
          </MediaQuery>
          <MediaQuery maxWidth={700}>
            {this.state.company_info != null && (
              <div>
                <div>
                  <Card style={styles.paperMobile}>
                    <HeaderCard
                      store={store}
                      health={this.state.health}
                      company_info={this.state.company_info}
                      references={this.state.references}
                      cases={this.state.case_studies}
                      handler={this.toggleQuoteForm}
                    />
                  </Card>

                </div>

                {((this.state.images != null && this.state.images.length != 0) || accountType == 2) && (
                  <Paper style={styles.paperMobile}>
                    <GalleryCard id={this.props.devId} images={this.state.images} store={store} />
                  </Paper>
                )}

                {this.state.health != null && (
                  <Card style={styles.paperMobile}>
                    <CompanyInfoCard
                      id={this.props.devId}
                      contact={this.state.contact}
                      company_info={this.state.company_info}
                      health={this.state.health}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.case_studies != null && (
                  <Card style={styles.paperMobile}>
                    <CasesCard
                      id={this.props.devId}
                      case_studies={this.state.case_studies}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.references != null && (
                  <Card style={styles.paperMobile}>
                    <ReferencesCard
                      id={this.props.devId}
                      references={this.state.references}
                      store={store}
                    />
                  </Card>
                )}

                {this.state.feedback != null && (this.state.feedback.length != 0 || accountType == 2) && (
                  <Card style={styles.paperMobile}>
                    <FeedbacksCard id={this.props.devId} feedback={this.state.feedback} store={store} />
                  </Card>
                )}

                <Card style={styles.paperMobile}>
                  <ProcessInfoCard
                    id={this.props.devId}
                    process_info={this.state.process_info}
                    store={store}
                  />
                </Card>
              </div>
            )}
          </MediaQuery>

          <div
            style={{
              paddingTop: "5em"
            }}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}


export class HeaderCard extends Component {
  requestQuote(event) {

    window.amplitude.getInstance().logEvent("REQUEST_QUOTE_INITIATED");
    this.props.handler();
  }

  openSkype(event) {
    window.SkypeWebControl.SDK.Chat.startChat({
      ConversationId: this.props.company_info.skype,
      ConversationType: "agent"
    });
  }

  render() {
    var store = this.props.store;

    var health = this.props.health;
    var data = this.props.company_info;
    var references = this.props.references;
    var cases = this.props.cases;

    var date = new Date();
    var dateString = "Invalid Time";

    var accountType = 0;

    if (store.getState().userData != null) {
      accountType = store.getState().userData.acc_type;
    }

    try {
      dateString = date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: data.timezone
      });
    } catch (e) {
      console.log("well fuck me");
      dateString = data.timezone + "Invalid";
    }

    if (data.name != null) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div style={{ display: "flex", marginTop: 15 }}>
            <div style={{ marginRight: 25, marginLeft: 20, width: 60 }}>
              <img
                src={data.image}
                style={{
                  height: 70,
                  width: 70,
                  display: "block",
                  margin: "0 auto"
                }}
              />
            </div>

            <div style={{ verticalAlign: "middle" }}>
              <div
                style={{
                  fontSize: "1.3em",
                  fontWeight: typography.fontWeightMedium,
                  paddingTop: ".50em",
                  fontFamily: "Avenir Next",
                  fontWeight: 600,
                  lineHeight: "normal",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                  color: "#47535f"
                }}
              >
                {data.name}
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  fontWeight: typography.fontWeightRegular,
                  fontFamily: "Avenir Next",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                  color: "#47535f"
                }}
              >
                {data.hq}
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "0.5em",
              maxWidth: "100%",
              marginLeft: 20,
              color: "#0085ff",
              marginBottom: "1em"
            }}
          >
            {health != null && (
              <div
                style={{
                  marginTop: "1em",
                  maxWidth: "100%",
                  color: "#0085ff"
                }}
              >
                {(health.ver_ref_count == 0 ||
                  health.ver_ref_count == null) && (
                    <div style={styles.verifiedInfoGray}>
                      <img
                        src={require("../images/notapproved.svg")}
                        style={styles.icon}
                      />
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle"
                        }}
                      >
                        <a
                          href="#references"
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          0 Verified References
                      </a>
                      </div>
                    </div>
                  )}

                {(health.ver_case_count == 0 ||
                  health.ver_case_count == null) && (
                    <div style={styles.verifiedInfoGray}>
                      <img
                        src={require("../images/notapproved.svg")}
                        style={styles.icon}
                      />
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle"
                        }}
                      >
                        <a
                          href="#work"
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          0 Verified Case Studies
                      </a>
                      </div>
                    </div>
                  )}

                {health.ver_case_count != 0 && health.ver_case_count != null && (
                  <div style={styles.verifiedInfo}>
                    <img
                      src={require("../images/approve_blue.svg")}
                      style={styles.icon}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      <a
                        href="#work"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        {health.ver_case_count} Verified Case Studies{" "}
                      </a>
                    </div>
                  </div>
                )}

                {health.ver_ref_count != 0 && health.ver_ref_count != null && (
                  <div style={styles.verifiedInfo}>
                    <img
                      src={require("../images/approve_blue.svg")}
                      style={styles.icon}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      <a
                        href="#references"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        {health.ver_ref_count} Verified References
                      </a>
                    </div>
                  </div>
                )}

                {(health.di_tested == false || health.di_tested == null) && (
                  <div style={styles.verifiedInfoGray}>
                    <img
                      src={require("../images/notapproved.svg")}
                      style={styles.icon}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      <a
                        href="#experiences"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Aloa Certified
                      </a>
                    </div>
                  </div>
                )}

                {health.di_tested == true && health.di_tested != null && (
                  <div style={styles.verifiedInfo}>
                    <img
                      src={require("../images/approve_blue.svg")}
                      style={styles.icon}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      <a
                        href="#experiences"
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Aloa Certified
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}

            {health == null && (
              <div
                style={{
                  marginTop: "1em",
                  maxWidth: "100%",
                  color: "#0085ff"
                }}
              >
                <div style={styles.verifiedInfoGray}>
                  <img
                    src={require("../images/notapproved.svg")}
                    style={styles.icon}
                  />
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle"
                    }}
                  >
                    <a
                      href="#references"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      0 Verified References
                    </a>
                  </div>
                </div>

                <div style={styles.verifiedInfoGray}>
                  <img
                    src={require("../images/notapproved.svg")}
                    style={styles.icon}
                  />
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle"
                    }}
                  >
                    <a
                      href="#work"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      0 Verified Case Studies
                    </a>
                  </div>
                </div>

                <div style={styles.verifiedInfoGray}>
                  <img
                    src={require("../images/notapproved.svg")}
                    style={styles.icon}
                  />
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle"
                    }}
                  >
                    <a
                      href="#experiences"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      Aloa Certified
                    </a>
                  </div>
                </div>
              </div>
            )}

            {data.skype != null && data.skype != "" && accountType == 2 && (
              <div style={{ paddingTop: 10, paddingRight: 15 }}>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: "#00aff0",
                    borderColor: "#00aff0"
                  }}
                  onClick={event => this.openSkype(event)}
                >
                  Chat on Skype
                </Button>
              </div>
            )}

            {accountType != 2 && (
              <div style={{ paddingTop: 10, paddingRight: 15 }}>
                <Button
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={event => this.requestQuote(event)}
                >
                  Request Quote
                </Button>
              </div>
            )}
          </div>
        </MuiThemeProvider>
      );
    } else {
      return null;
    }
  }
}

/*
export class POCCard extends Component {
  openSkype(event, skype) {
    window.SkypeWebControl.SDK.Chat.startChat({
      ConversationId: skype,
      ConversationType: "agent"
    });
  }

  render() {
    console.log("LEL");

    var t = this.props.contact;

    var date = new Date();
    var dateString = "Invalid Time";
    var isDayTime = false;

    try {
      dateString = date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: t.timezone
      });

      var hours = new Date("January 31 2018 " + dateString).getHours();
      isDayTime = hours > 6 && hours < 20;
    } catch (e) {
      console.log("well fuck me");
      dateString = t.timezone + "Invalid Timezone";
    }

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          <div style={{}}>
            <div style={{ display: "inline-block", verticalAlign: "middle" }}>
              <div style={{ fontSize: 20 }}>{t.name}</div>

              <div style={{ fontSize: 13, color: "#a1a1a1" }}>{t.title}</div>
            </div>

            <div
              style={{
                float: "right",
                display: "inline-block",
                verticalAlign: "middle"
              }}
            >
              {t.image != null && (
                <img src={t.image} style={styles.pocProPicSmall} />
              )}

              {t.image == null && (
                <Avatar
                  color={"#ffffff"}
                  backgroundColor={"#000000"}
                  size={35}
                  style={{ fontSize: 15 }}
                >
                  {t.name.substring(0, 2).toUpperCase()}
                </Avatar>
              )}
            </div>
          </div>

          <div style={{ paddingTop: 15 }}>
            <Button
              type="primary"
              size="large"
              style={{
                width: "100%",
                backgroundColor: "#00aff0",
                borderColor: "#00aff0"
              }}
              onClick={event => this.openSkype(event, t.skype)}
            >
              Chat on Skype
            </Button>
          </div>

          <div style={{ marginTop: 15 }}>
            <div
              style={{
                textAlign: "center",
                fontSize: 12,
                color: "rgba(155, 155, 155, 0.87)"
              }}
            >
              {isDayTime && (
                <img
                  src={require("../images/sun.svg")}
                  style={{
                    height: 15,
                    width: 15,
                    marginRight: 5,
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                />
              )}
              {!isDayTime && (
                <img
                  src={require("../images/moon.svg")}
                  style={{
                    height: 15,
                    width: 15,
                    marginRight: 5,
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                />
              )}

              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle"
                }}
              >
                Local Time {dateString}
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}*/
