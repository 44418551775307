import { combineReducers } from 'redux'
import firebase, { auth, db } from '../fire.js';
import { projData } from './dash';

export const userData = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_BALANCE':
      return {
        ...state,
        devpay_info: {
          balance: action.balance
        }
      }
    case 'LOGIN_USER':
      return action.user
    case 'LOGIN_USER':
      return null
    default:
      return state
  }
}

export const searchResults = (state = null, action) => {
  switch (action.type) {
    case 'PARSE_RESULTS':
      return {
        data: action.results,
        finished: true
      }
    default:
      return state
  }
}

export const devPayData = (state = null, action) => {
  switch (action.type) {
    case 'IMPORT_HISTORY':
      return {
        data: action.history
      }
    default:
      return state
  }
}

export const editingData = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_CASE_DATA':
    return {
      ...state,
      case_studies: action.case_studies
    }
    case 'UPDATE_REFERENCES':
    return {
      ...state,
      references: action.references
      
    }
    case 'UPDATE_FEEDBACK':
    return {
      ...state,
      feedback: action.feedback
      
    }
    case 'UPDATE_COM_INFO_DATA':
    return {
      ...state,
      company_info: action.company_info
    }
    case 'UPDATE_PROC_INFO_DATA':
    return {
      ...state,
      process_info: action.process_info
    }
    case 'UPDATE_HEALTH_DATA':
    return {
      ...state,
      health: action.health
    }
    case 'UPDATE_IMAGES_DATA':
    return {
      ...state,
      images: action.images
    }
    default:
      return state
  }
}

const filtering = combineReducers({
  searchResults,
  userData,
  projData,
  devPayData,
  editingData
})

export default filtering
