import React, { Component } from "react";
import * as Colors from "material-ui/styles/colors";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import FlatButton from "material-ui/FlatButton";
import logo from "./images/aloa_small_line.png";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";

import { Button, Select, Avatar, Menu, Icon, Drawer } from "antd";
import firebase from "firebase";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: "#ffffff",
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.white,
    alternateTextColor: "#ffffff"
  },
  appBar: {
    height: 60
  }
});

export class TopBar extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      current: "mail",
      visible: false
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };
  onClose = () => {
    this.setState({
      visible: false
    });
  };

  viewAccount() {
    window.amplitude.getInstance().logEvent("ACCOUNT_BUTTON_CLICKED");

    // window.location.href = "/account";
    window.location.href = process.env.REACT_APP_ACCOUNTS_URL;
  }

  render() {
    var store = this.props.store;

    var currentUser = {};
    if (store.getState().userData != null && firebase.auth().currentUser) {
      currentUser = store.getState().userData;
    }

    if (currentUser.name == null || currentUser.name == "") {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <AppBar
            position="static"
            color="#ffffff"
            style={{
              boxShadow: "none",
              paddingLeft: "10px",
              backgroundColor: "#ffffff",
              borderBottom: "1px solid #efefef"
            }}
          >
            <Toolbar>
              <MediaQuery minWidth={701}>
                <div style={{ flex: 1 }}>
                  <div style={{ display: "inline-block" }}>
                    <a href={process.env.REACT_APP_MANAGE_URL}>
                      <img
                        src={logo}
                        alt="Logo"
                        height="28"
                        vspace="13"
                        hspace="14"
                      />
                    </a>
                  </div>

                  <div style={{ display: "inline-block", marginLeft: "50px" }}>
                    <a
                      style={{
                        color: "#272727",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Avenir Next"
                      }}
                      href={process.env.REACT_APP_MANAGE_URL}
                    >
                      Manage
                    </a>
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "25px" }}>
                    <a
                      style={{
                        color: "#272727",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Avenir Next"
                      }}
                      href={process.env.REACT_APP_PAY_URL}
                    >
                      Pay
                    </a>
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "25px" }}>
                    <a
                      style={{
                        color: "#2d89f6",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Avenir Next"
                      }}
                      href={process.env.REACT_APP_PARTNERS_URL}
                    >
                      Partners
                    </a>
                  </div>
                </div>

                <div style={{ paddingTop: 2, paddingRight: 10, flext: 0.3 }}>
                  <a
                    // href="/partners/login"
                    href={`${
                      process.env.REACT_APP_ACCOUNTS_URL
                      }/login?ref=partners`}
                    style={{
                      fontSize: "16px",
                      color: "#000000",
                      fontFamily: "Avenir Next",
                      fontWeight: 500,
                      underline: "none"
                    }}
                  >
                    Sign In
                  </a>
                </div>
              </MediaQuery>

              <MediaQuery maxWidth={700}>
                <div style={{ flex: 1 }}>
                  <div
                    style={{ display: "inline-block" }}
                    onClick={this.showDrawer}
                  >
                    <img
                      style={{ width: "20px" }}
                      src={require("./images/hamburger.svg")}
                    />
                  </div>

                  <Drawer
                    title="Menu"
                    placement="left"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                  >
                    <Menu mode="inline" defaultSelectedKeys={["search"]}>
                      <Menu.Item key="manage">
                        <a href={process.env.REACT_APP_MANAGE_URL}>Manage</a>
                      </Menu.Item>
                      <Menu.Item key="pay">
                        <a href={process.env.REACT_APP_PAY_URL}>Pay</a>
                      </Menu.Item>
                      <Menu.Item key="search">
                        <a href={process.env.REACT_APP_PARTNERS_URL}>Partners</a>
                      </Menu.Item>
                    </Menu>
                  </Drawer>

                  <div style={{ display: "inline-block" }}>
                    <a href={process.env.REACT_APP_MANAGE_URL}>
                      <img
                        src={logo}
                        alt="Logo"
                        height="28"
                        vspace="13"
                        hspace="14"
                      />
                    </a>
                  </div>
                </div>

                <div style={{ paddingTop: 2, paddingRight: 10, flext: 0.3 }}>
                  <a
                    // href="/partners/login"
                    href={`${
                      process.env.REACT_APP_ACCOUNTS_URL
                      }/login?ref=partners`}
                    style={{
                      fontSize: "16px",
                      color: "#000000",
                      fontFamily: "Avenir Next",
                      fontWeight: 500,
                      underline: "none"
                    }}
                  >
                    Sign In
                  </a>
                </div>
              </MediaQuery>
            </Toolbar>
          </AppBar>
        </MuiThemeProvider>
      );
    }

    var name = currentUser.name;
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <AppBar
          position="static"
          color="#ffffff"
          style={{ boxShadow: "none", borderBottom: "1px solid #e4e4e4" }}
        >
          <Toolbar>
            <MediaQuery minWidth={701}>
              <div style={{ flex: 1 }}>
                <div style={{ display: "inline-block" }}>
                  <a href={process.env.REACT_APP_MANAGE_URL}>
                    <img
                      src={logo}
                      alt="Logo"
                      height="28"
                      vspace="13"
                      hspace="14"
                    />
                  </a>
                </div>

                <div style={{ display: "inline-block", marginLeft: "50px" }}>
                  <a
                    style={{
                      color: "#272727",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Avenir Next"
                    }}
                    href={process.env.REACT_APP_MANAGE_URL}
                  >
                    Manage
                  </a>
                </div>
                <div style={{ display: "inline-block", marginLeft: "25px" }}>
                  <a
                    style={{
                      color: "#272727",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Avenir Next"
                    }}
                    href={process.env.REACT_APP_PAY_URL}
                  >
                    Pay
                  </a>
                </div>
                <div style={{ display: "inline-block", marginLeft: "25px" }}>
                  <Link
                    style={{
                      color: "#2d89f6",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Avenir Next"
                    }}
                    to="/"
                  >
                    Partners
                  </Link>
                </div>
              </div>

              <div style={{ paddingTop: 2, paddingRight: 10, flext: 0.3 }}>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "10px",
                    textAlign: "right"
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Brown",
                      color: "#5A7685",
                      fontSize: "13px",
                      fontWeight: 200
                    }}
                  >
                    {/* <Link
                      style={{
                        fontFamily: "Brown",
                        color: "#5A7685",
                        fontSize: "13px",
                        fontWeight: 300,
                        textDecoration: "none"
                      }}
                      to="/account"
                    >
                      {currentUser.name}
                    </Link> */}
                    <a
                      style={{
                        fontFamily: "Brown",
                        color: "#5A7685",
                        fontSize: "13px",
                        fontWeight: 300,
                        textDecoration: "none"
                      }}
                      href={process.env.REACT_APP_ACCOUNTS_URL}
                    >
                      {currentUser.name}
                    </a>
                  </div>
                  <div
                    style={{
                      fontFamily: "Brown",
                      color: "#A8A8A8",
                      fontSize: "12px",
                      fontWeight: 300
                    }}
                  >
                    {currentUser.company}
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "#ffffff",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginTop: "3px"
                  }}
                >
                  {currentUser.image != null && currentUser.image != "" && (
                    // <Link to="/account">
                    //   <img
                    //     style={{
                    //       width: "35px",
                    //       height: "35px",
                    //       borderRadius: "50%"
                    //     }}
                    //     src={currentUser.image}
                    //   />
                    // </Link>
                    <a href={process.env.REACT_APP_ACCOUNTS_URL}>
                      <img
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%"
                        }}
                        src={currentUser.image}
                      />
                    </a>
                  )}

                  {(currentUser.image == null || currentUser.image == "") && (
                    // <Link to="/account">
                    //   <Avatar
                    //     style={{
                    //       color: "#f56a00",
                    //       fontSize: "15px",
                    //       height: "35px",
                    //       width: "35px",
                    //       backgroundColor: "#fde3cf",
                    //       verticalAlign: "middle"
                    //     }}
                    //   >
                    //     {initials}
                    //   </Avatar>
                    // </Link>
                    <a href={process.env.REACT_APP_ACCOUNTS_URL}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          fontSize: "15px",
                          height: "35px",
                          width: "35px",
                          backgroundColor: "#fde3cf",
                          verticalAlign: "middle"
                        }}
                      >
                        {initials}
                      </Avatar>
                    </a>
                  )}
                </div>
              </div>
            </MediaQuery>

            <MediaQuery maxWidth={700}>
              <div style={{ flex: 1 }}>
                <div
                  style={{ display: "inline-block" }}
                  onClick={this.showDrawer}
                >
                  <img
                    style={{ width: "20px" }}
                    src={require("./images/hamburger.svg")}
                  />
                </div>

                <Drawer
                  title="Menu"
                  placement="left"
                  closable={true}
                  onClose={this.onClose}
                  visible={this.state.visible}
                >
                  <Menu mode="inline" defaultSelectedKeys={["search"]}>
                    <Menu.Item key="manage">
                      <a href={process.env.REACT_APP_MANAGE_URL}>Manage</a>
                    </Menu.Item>
                    <Menu.Item key="pay">
                      <a href={process.env.REACT_APP_PAY_URL}>Pay</a>
                    </Menu.Item>
                    <Menu.Item key="search">
                      <Link to="/">Partners</Link>
                    </Menu.Item>
                  </Menu>
                </Drawer>

                <div style={{ display: "inline-block" }}>
                  <a href={process.env.REACT_APP_MANAGE_URL}>
                    <img
                      src={logo}
                      alt="Logo"
                      height="28"
                      vspace="13"
                      hspace="14"
                    />
                  </a>
                </div>
              </div>

              <div style={{ paddingTop: 2, paddingRight: 10, flext: 0.3 }}>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginTop: "3px"
                  }}
                >
                  {currentUser.image != null && currentUser.image != "" && (
                    // <Link to="/account">
                    //   <img
                    //     style={{
                    //       width: "35px",
                    //       height: "35px",
                    //       borderRadius: "50%"
                    //     }}
                    //     src={currentUser.image}
                    //   />
                    // </Link>
                    <a href={process.env.REACT_APP_ACCOUNTS_URL}>
                      <img
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%"
                        }}
                        src={currentUser.image}
                      />
                    </a>
                  )}

                  {(currentUser.image == null || currentUser.image === "") && (
                    <a href={process.env.REACT_APP_ACCOUNTS_URL}>
                      <Avatar
                        style={{
                          color: "#f56a00",
                          fontSize: "15px",
                          height: "35px",
                          width: "35px",
                          backgroundColor: "#fde3cf",
                          verticalAlign: "middle"
                        }}
                      >
                        {initials}
                      </Avatar>
                    </a>
                  )}
                </div>
              </div>
            </MediaQuery>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    );
  }
}
