import firebase, {auth, db} from '../fire.js';

export const parseResults = results => {
    return {type: 'PARSE_RESULTS', results: results}
}

export const fetchResultsForQuery = (query) => {
    
    return function (dispatch) {

        

        const algoliasearch = require('algoliasearch');
        const client = algoliasearch("3XH40BADRA", "f8c49228bd0d2ba764dbebb55f2d0569");
        const index = client.initIndex("profiles");


        // Perform an Algolia search:
        // https://www.algolia.com/doc/api-reference/api-methods/search/

        index.search({
            query,
            highlightPreTag: '<b style="border-bottom: solid 2px rgba(0,137,255,0.3);">',
            highlightPostTag: '</b>',
            removeWordsIfNoResults: 'allOptional',
            filters: 'public=1'
        }).then(responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            
            
            dispatch(parseResults(responses.hits))
        });
        
    }
}