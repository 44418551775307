import React, { Component } from 'react';
import Paper from 'material-ui/Paper';

export default class Card extends Component {
    render() {
        return (
            <Paper style={{textAlign: "left", fontFamily: "Avenir Next", fontSize: "14px", fontWeight: 400, color: "#7d7d7d", textDecoration: "none", marginBottom: 30, boxShadow: "0 1px 5px 0 rgba(0,0,0,.1)", borderRadius: "8px", padding: "20px", ...this.props.style}}>
                {this.props.children}
            </Paper>
        )
    }
}