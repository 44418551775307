import { combineReducers } from 'redux'

export const projData = (state = null, action) => {
    switch (action.type) {
      case 'LOAD_PROJECT':
        console.log("called load project!")
        return action.project
      default:
        return state
    }
}


const dash = combineReducers({
  projData
})

export default dash