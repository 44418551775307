
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { db } from "../../fire.js";
import firebase from "../../fire.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import ImageGallery from "react-image-gallery";
import "../../App.css";

import { Button, Select, Avatar, Input, Upload, Icon, Modal, message, Popconfirm, Switch } from "antd";
import { typography } from "material-ui/styles";
import * as Colors from "material-ui/styles/colors";
const { TextArea } = Input;
const Option = Select.Option;

const algoliasearch = require('algoliasearch');

const ALGOLIA_ID = "3XH40BADRA";
const ALGOLIA_ADMIN_KEY = "d5013f3793e173fc34ca0a0a075a365f"
const ALGOLIA_SEARCH_KEY = "f8c49228bd0d2ba764dbebb55f2d0569";

const ALGOLIA_INDEX_NAME = 'profiles';
const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);

const muiTheme = getMuiTheme({
    palette: {
      textColor: Colors.darkBlack,
      primary1Color: "#002F49",
      primary2Color: Colors.indigo700,
      accent1Color: Colors.redA200,
      pickerHeaderColor: Colors.white,
      alternateTextColor: "#ffffff"
    },
    appBar: {
      height: 60
    }
  });
  

const styles = {
    navigation: {
      fontSize: 15,
      fontWeight: typography.fontWeightLight,
      color: "grey",
      paddingBottom: 15,
      display: "block"
    },
    title: {
      fontSize: "1.2em",
      paddingTop: ".50em",
      fontFamily: "Avenir Next",
      fontWeight: 500,
      lineHeight: "normal",
      wordWrap: "break-word",
      maxWidth: "100%"
    },
    smallTitle: {
      fontSize: "1em",
      fontWeight: typography.fontWeightRegular,
      color: "#AEADAD",
      fontFamily: "Avenir Next",
      wordWrap: "break-word",
      maxWidth: "100%"
    },
    smallTitleTime: {
      fontSize: "0.7em",
      fontWeight: typography.fontWeightRegular,
      color: "#AEADAD",
      fontFamily: "Avenir Next",
      wordWrap: "break-word",
      maxWidth: "100%"
    },
    subTitle: {
      paddingTop: "0.2em",
      paddingRight: "1.5em",
      paddingBottom: "1.0em",
      fontSize: "1.0em",
      fontWeight: typography.fontWeightRegular,
      color: "#666",
      fontFamily: "Avenir Next"
    },
    cardLeft: {
      padding: 30,
      marginTop: "1.3em",
      marginLeft: "25%",
      marginRight: "1%",
      width: "24.5%",
      float: "left",
      marginBottom: "1.3em",
      fontFamily: "Avenir Next"
    },
    cardRight: {
      padding: 30,
      marginTop: "1.3em",
      width: "24.5%",
      float: "left",
      fontFamily: "Avenir Next"
    },
    cardBoth: {
      marginTop: "1.3em",
      marginLeft: "25%",
      marginRight: "25%",
      clear: "both",
      fontFamily: "Avenir Next"
    },
    proPic: {
      marginTop: 15,
      height: "100%",
      width: "100%"
    },
    rightBlock: {
      float: "left",
      marginLeft: 15,
      paddingBottom: 15
    },
    paperTablet: {
      padding: 30,
      marginTop: "1.3em",
      marginLeft: "15%",
      marginRight: "15%",
      clear: "both",
      fontFamily: "Avenir Next",
      overflow: "auto",
      borderRadius: "4px"
    },
    paperMobile: {
      marginTop: "2.3em",
      marginLeft: "5%",
      marginRight: "5%",
      fontFamily: "Avenir Next",
      borderRadius: "4px",
      overflow: "hidden",
      padding: "0px"
    },
    headerPaper: {
      paddingTop: 0,
      paddinLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      marginTop: "1.2em",
      marginLeft: "25%",
      marginRight: "25%",
      clear: "both",
      overflow: "auto",
      borderRadius: "4px"
    },
    profileInfoCard: {
      padding: 1,
      width: "100%",
      float: "left",
      fontFamily: "Avenir Next",
      clear: "both",
      borderRadius: "4px"
    },
    paper: {
      marginTop: "2.3em",
      marginLeft: "490px",
      marginRight: "170px",
      fontFamily: "Avenir Next",
      borderRadius: "4px",
      overflow: "hidden",
      padding: "10px"
    },
    paperGallery: {
      marginTop: "2.3em",
      marginLeft: "490px",
      marginRight: "170px",
      fontFamily: "Avenir Next",
      borderRadius: "4px",
      overflow: "hidden",
      padding: 0
    },
    stickDiv: {
      marginLeft: "170px",
      width: "260px",
      marginRight: "60px",
      float: "left",
      fontFamily: "Avenir Next",
      position: "-webkit-sticky",
      position: "sticky",
      top: "20px"
    },
    stickDivSafari: {
      marginLeft: "170px",
      width: "260px",
      marginRight: "60px",
      float: "left",
      fontFamily: "Avenir Next",
      position: "-webkit-sticky",
      top: "20px"
    },
    headerPaperMobile: {
      paddingTop: 0,
      paddinLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      marginTop: "1.2em",
      marginLeft: "2%",
      marginRight: "2%",
      clear: "both",
      overflow: "auto",
      borderRadius: "4px"
    },
    headerPaperTablet: {
      paddingTop: 0,
      paddinLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      marginTop: "1.2em",
      marginLeft: "15%",
      marginRight: "15%",
      clear: "both",
      overflow: "auto",
      borderRadius: "4px"
    },
    accountHeaderItem: {
      paddingTop: "0.3em",
      fontSize: "1.0em",
      fontWeight: typography.fontWeightLight,
      color: "#666",
      fontFamily: "Avenir Next",
      wordWrap: "break-word",
      maxWidth: "100%"
    },
    cardTitle: {
      fontSize: 24,
      fontWeight: typography.fontWeightMedium,
      marginTop: 5,
      wordWrap: "break-word",
      maxWidth: "100%"
    },
    caseHeader: {
      fontSize: 15,
      paddingTop: 25,
      display: "flex"
    },
    lineItem: {
      fontSize: 15,
      paddingTop: 25
    },
    lineItemFirst: {
      fontSize: 15,
      paddingTop: 15
    },
    lineTitle: {
      fontWeight: 400,
      fontSize: "15px",
      fontFamily: "Avenir Next",
      width: "100%",
      color: "#353535",
      paddingBottom: "4px",
      color: "#55360"
    },
    lineValue: {
      fontWeight: 400,
      width: "100%",
      fontSize: "15px",
      whiteSpace: "pre-line",
      color: "#353535"
    },
    verifiedInfo: {
      height: "30px",
      width: "100%",
      fontSize: "1em",
      color: "#000000",
      position: "relative",
      top: 0,
      userSelect: "none",
      textDecoration: "none",
      marginRight: 5,
      marginBottom: 5
    },
    verifiedInfoGray: {
      height: "30px",
      width: "100%",
      fontSize: "1em",
      color: "#000000",
      position: "relative",
      top: 0,
      userSelect: "none",
      textDecoration: "none",
      marginRight: 5,
      marginBottom: 5
    },
    icon: {
      height: 20,
      width: 20,
      marginRight: 15,
      display: "inline-block",
      verticalAlign: "middle"
    },
    actionCard: {
      padding: 15,
      marginTop: "1.3em",
      width: "100%",
      float: "left",
      fontFamily: "Avenir Next",
      clear: "both"
    },
    actionCardMobile: {
      padding: 15,
      marginTop: "1.3em",
      marginLeft: "5%",
      marginRight: "5%",
      fontFamily: "Avenir Next",
      borderRadius: "4px",
      overflow: "hidden"
    },
    proPicSmall: {
      float: "right",
      height: 35,
      marginRight: 10,
      borderRadius: "4px",
      display: "inline-block",
      verticalAlign: "middle"
    },
    caseIcon: {
      float: "left",
      height: 35,
      marginRight: 10,
      marginTop: 5,
      borderRadius: "4px",
      display: "inline-block",
      verticalAlign: "middle"
    },
    proPicSmallMobile: {
      float: "left",
      height: 35,
      marginRight: 10,
      borderRadius: "4px"
    },
    refProPicSmall: {
      float: "left",
      height: 35,
      marginRight: 10,
      borderRadius: "50%",
      display: "inline-block",
      verticalAlign: "middle"
    },
    pocProPicSmall: {
      float: "left",
      height: 35,
      marginRight: 10,
      borderRadius: "50%",
      display: "inline-block",
      verticalAlign: "middle"
    },
    nameLabel: {
      fontSize: "1.2em",
      fontFamily: "Avenir Next",
      fontWeight: 500,
      lineHeight: "normal",
      wordWrap: "break-word",
      maxWidth: "100%",
      display: "inline-block",
      verticalAlign: "middle",
      color: "#000000"
    },
    nameLabelMobile: {
      fontSize: "1.5em",
      fontFamily: "Avenir Next",
      fontWeight: 500,
      widht: "100%",
      lineHeight: "normal",
      wordWrap: "break-word",
      maxWidth: "100%",
      color: "#000000"
    },
    refNameLabel: {
      fontSize: "1.5em",
      fontFamily: "Avenir Next",
      fontWeight: 500,
      lineHeight: "normal",
      wordWrap: "break-word",
      maxWidth: "100%",
      color: "#000000"
    },
    tag: {
      color: "#2f54eb",
      background: "#f0f5ff",
      borderColor: "#adc6ff",
      borderRadius: "4px",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 5,
      marginTop: 5,
      fontSize: "0.9em",
      display: "inline-block"
    },
    cardHeader: {
      fontSize: 25,
      fontWeight: 600,
      paddingTop: 30,
      paddingLeft: 30,
      backgroundColor: "#ffffff",
      color: "black"
    },
    infoRow: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 30
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {

    // Create a root reference
    var storageRef = firebase.storage().ref();
  
    // Create a reference to 'mountains.jpg'
    var mountainsRef = storageRef.child("mountains.jpg");
  
    var randomId =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
  
    var uploadTask = storageRef
      .child("images" + "/" + randomId + ".jpg")
      .put(file);
  
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
      },
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(
          function(downloadURL) {
            console.log("File available at", downloadURL);
            onSuccess(downloadURL);
          }.bind(this)
        );
      }.bind(this)
    );
  
    };

export class GalleryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          editing: false,
          changed: {},
          previewVisible: false,
        previewImage: ''
        }; // <- set up react state
      }

      editCard(event) {
        this.setState({ editing: true });
      }

      cancelEdit(event) {
        var data = this.props.images;
  
        
        var fileList = []

 
        var id = 0
        data.forEach(function (image) {
            var file = {
                uid: id++,
                url: image
            }
            fileList.push(file)
        })

       
        this.setState({ images: data, fileList: fileList, editing: false });
      }

      saveCard(event) {
        var imagesTemp = []
        this.state.fileList.forEach(function(file) {
            if(file.url != null && file.url != "") {
                imagesTemp.push(file.url)
            } else {
                if(file.response != null && file.response != "") {
                    imagesTemp.push(file.response)
                }
            }
        })

        console.log("image list is -> ", imagesTemp)

        var images = {images: imagesTemp}
    
    
        db.collection("profiles")
          .doc(this.props.id)
          .set(images, { merge: true })
          .then(
            function() {
              console.log("Document successfully written!");

              const index = client.initIndex(ALGOLIA_INDEX_NAME);
              index.partialUpdateObject({
                images: imagesTemp,
                objectID: this.props.id
              }, function(err, content) {
                if (err) {
                  console.log("oh shit an error")
                } else {
                  console.log("pushed to Aloglia!")
                
                  console.log(content);
                }
              });

              this.setState({ editing: false, images: imagesTemp });
            }.bind(this)
          )
          .catch(
            function(error) {
              console.error("Error writing document: ", error);
            }.bind(this)
          );
      }

      componentDidMount() {
        var data = this.props.images;

        var fileList = []

        var id = 0
        data.forEach(function (image) {
            var file = {
                uid: id++,
                url: image
            }
            fileList.push(file)
        })

       
        this.setState({ images: data, fileList: fileList });
        
      }

      handleCancel = () => this.setState({ previewVisible: false })

      handlePreview = (file) => {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true,
        });
      }
    
      handleChange = ({ fileList }) => this.setState({ fileList })



    render() {
      const { store } = this.props;
      var data = this.state.images;
      var images = [];

      const { previewVisible, previewImage, fileList } = this.state;

      var accountType = 0;

      if (store.getState().userData != null) {
        accountType = store.getState().userData.acc_type;
      }

      const uploadButton = (
        <div>
          <Icon type="plus" />
          <div className="ant-upload-text">Upload</div>
        </div>
      );
  
     
  
      console.log("OH SHIT");
      console.log(data);
  
      if(data != null) {
        data.forEach(function(t) {
            images.push({ original: t });
          });    
      }
      
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
          {!this.state.editing && (
            <div style={{}}>
              <ImageGallery
                items={images}
                showPlayButton={false}
                showFullscreenButton={false}
                showBullets={true}
                showThumbnails={false}
              />
            </div>
          )}

          {this.state.editing && (
            <div style={{margin: "30px"}}>
                 <div className="clearfix">
                    <Upload
                    customRequest={dummyRequest}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    >
                    {fileList.length >= 15 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </div>
            </div>
          )}

              {accountType == 2 && (
                <div style={{marginTop: "20px"}}>
                  {!this.state.editing && (
                    <Button
                      style={{
                        float: "right",
                        marginRight: 20,
                        marginBottom: 20
                      }}
                      onClick={event => this.editCard(event)}
                    >
                      Edit
                    </Button>
                  )}
  
                  {this.state.editing && (
                    <div style={{ float: "right" }}>
                      <Button
                        style={{ marginRight: 5, marginBottom: 20 }}
                        onClick={event => this.cancelEdit(event)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        style={{ marginRight: 20, marginBottom: 20 }}
                        onClick={event => this.saveCard(event)}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              )}
          </div>
        </MuiThemeProvider>
      );
    }
  }